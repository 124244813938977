import { Link } from "react-router-dom";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import { Tab, Tabs } from "react-bootstrap";
import Amenities from "../../../components/tabs/website-home/Amenities";
import WhyYourSpace from "../../../components/tabs/website-home/WhyYourSpace";
import Testimonial from "../../../components/tabs/website-home/Testimonial";
import Faqs from "../../../components/tabs/website-home/Faqs";
import Carousal from "../../../components/tabs/website-home/Carousal";

function Website() {
  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Website</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Website</a> <span>/</span>
              </li>
              <li>Home</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="main_tab">
        <Tabs
          defaultActiveKey="website_amenities"
          id="resident-details-view"
          className="mb-4"
        >
          <Tab eventKey="website_amenities" title="Amenities">
            <Amenities />
          </Tab>
          <Tab eventKey="website_carousal_banners" title="Carousel Banners">
            <Carousal />
          </Tab>
          <Tab eventKey="website_testimonials" title="Testimonials">
            <Testimonial />
          </Tab>
          <Tab eventKey="website_faqs" title="Common FAQs ">
            <Faqs />
          </Tab>
          <Tab eventKey="website_why_your-space" title="Why Your Space ">
            <WhyYourSpace />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default withDefaultDashBoardLayout(Website);
