import { useEffect, useState } from 'react';
import GlobalStateContext from './GlobalStateContext';
import  secureLocalStorage  from  "react-secure-storage";
import { fetchOnboardingList } from './helpers/services/api.services';

const GlobalStateProvider = ({ children }) => {
    const [roles, setRoles] = useState({});
    const [tabKey, setTabKey] = useState("")
    const [activeResidentStatus, setActiveResidentStatus] = useState('All onboardings');
    const [serachObject, setSerachObject] = useState({})
    let [checkin_count, setCheckinCount] = useState(0);
    let [verification_count, setVerificationCount] = useState(0);

    useEffect(()=>{
      loadUserRoles();
    },[])

    useEffect(()=>{
      if (Object.keys(roles).includes('admin') || Object.keys(roles).includes('warden') || Object.keys(roles).includes('super_admin') || Object.keys(roles).includes('tech_admin')) {
        fetchNotificationData();
      }
    },[roles])

    const loadUserRoles = async () =>{
        // let roles = await localStorage.getItem("roles")
        let roles = await secureLocalStorage.getItem("roles")
        if(roles){
            // setRoles(JSON.parse(roles))
            setRoles(roles)
        }
    }


    const fetchNotificationData = async () => {
      let response = await fetchOnboardingList(1);
      if (response && response.status === 'success') {
        setCheckinCount(response?.meta?.checkin_lead_count)
        setVerificationCount(response?.meta?.verification_lead_count)
      }
    };


  return (
    <GlobalStateContext.Provider value={{ roles, setRoles, tabKey, setTabKey, activeResidentStatus,setCheckinCount,setVerificationCount, setActiveResidentStatus, serachObject, setSerachObject, checkin_count, verification_count, fetchNotificationData }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;