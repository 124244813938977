import React, { useEffect, useState } from "react";
import { getWebsiteTestimonials } from "../../../helpers/services/websiteServices";
import ModalTestimonial from "../../../pages/website/home-page/modals/ModalTestimonial";

const Testimonial = () => {
  const [testimonialConfigData, settestimonialConfigData] = useState([]);
  const [testimonialMediaData, settestimonialMediaData] = useState([]);
  const [additional_data, setAdditional_data] = useState({})
  const [openModal, setOpenModal] = useState(false);
  const [testimonialIndex, setTestimonalIndex] = useState(null);
  const [modalAction, setModalAction] = useState("");
  const [mediaModal, setmediaModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const mediaModalHandler = () => {
    setmediaModal(!mediaModal);
  };

  const handler = () => {
    setOpenModal(!openModal);
  };

  const fetchtestimonialData = async () => {
    try {
      const data = await getWebsiteTestimonials();

      if (data.status == "success") {
        settestimonialConfigData(data.data.global_config_data.config_details);
        settestimonialMediaData(data.data.media_storages);
        setAdditional_data({
          id: data.data.global_config_data.id,
          name: data.data.global_config_data.name,
        });
      }
    } catch (error) {
      console.log("Error Fetching data ---> ", error);
    }
  };

  useEffect(() => {
    fetchtestimonialData();
  }, [openModal , deleteModal]);

  return (
    <div>
      <div className="container">
        <div className="d-flex justify-content-end">
          <button
            className="basic-button"
            onClick={() => {
              setModalAction("add");
              handler();
              setTestimonalIndex(null);
            }}
          >
            Add New Testimonial
          </button>
        </div>
        <div className="row ">
          {testimonialConfigData.length > 0 &&
            testimonialConfigData?.map((testimonalData, index) => (
              <div className="col-lg-4" key={index}>
                <div className=" p-2 mt-4 mx-1 card">
                  <div className="face front-face d-flex justify-content-between mb-2 p-2 bg-light">
                    <div className="testimonial">
                      <b>{testimonalData.city}</b>
                    </div>
                    <div className="testimonial">
                      <img
                        src="/images/trash-03.png"
                        alt=""
                        onClick={() => {
                          setDeleteModal(true);
                          setTestimonalIndex(index);
                        }}
                        style={{cursor : "pointer" , height : "3vh"}}
                      />
                    </div>
                  </div>
                  <div className="face back-face">
                    <div className="testimonial">{testimonalData.review}</div>
                  </div>

                  <div className="face front-face d-flex mt-2 p-2 bg-light justify-content-between">
                    <img
                      src={testimonalData.image}
                      alt="profile image"
                      className="profile rounded-circle "
                      style={{
                        height: "50px",
                        width: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        mediaModalHandler();
                        setTestimonalIndex(index);
                        setModalAction("update");
                      }}
                    />
                    <div className="pt-3 text-uppercase name">
                      <b>{testimonalData.name} </b>
                    </div>
                    <div className="pt-3 text-uppercase name">
                      <b>
                        {" "}
                        <img
                          src="/images/edit.svg"
                          alt="Edit Icon"
                          className="bg-white "
                          onClick={() => {
                            handler();
                            setModalAction("update");
                            setTestimonalIndex(index);
                          }}
                          style={{cursor : "pointer" , height : "3vh"}}
                        />
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <ModalTestimonial
        openModal={openModal}
        handler={setOpenModal}
        testimonialConfigData={testimonialConfigData}
        testimonialIndex={testimonialIndex}
        actionType={modalAction}
        testimonialMedialData={testimonialMediaData}
        additional_data={additional_data}
        mediaModal={mediaModal}
        mediaModalHandler={setmediaModal}
        deleteModal={deleteModal}
        deleteModalHandler={setDeleteModal}
      />
    </div>
  );
};

export default Testimonial;
