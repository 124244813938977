import React, { useEffect, useState } from "react";
import { getWebsiteFaqs, updateWebsiteFaqs } from "../../../helpers/services/websiteServices";
import { Accordion, Modal } from "react-bootstrap";
import FaQModal from "../../../pages/website/home-page/modals/ModalFaQ";
import toast from "react-hot-toast";

const Faqs = () => {
  const [faqConfigData, setfaqConfigData] = useState([]);
  const [faqMediaData, setfaqMediaData] = useState([]);
  const [faqIndex, setFaqIndex] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const handler = () => {
    setOpenModal(!openModal);
  };

  const fetchfaqData = async () => {
    try {
      const data = await getWebsiteFaqs();

      if (data.status == "success") {
        setfaqConfigData(data.data.global_config_data.config_details);
        setfaqMediaData(data.data.media_storages);
      }
    } catch (error) {
      console.log("Error Fetching data ---> ", error);
    }
  };

  const handleDeleteFaq = async()=>{
    try {

      const filterData = faqConfigData.filter((data, ind) => ind !== deleteIndex);

      const bodyData = {
        config_details: filterData,
      };
      const response = await updateWebsiteFaqs(bodyData);

      if (response.status == "success") {
        toast.success("Faq deleted")
        setDeleteIndex(null)
        setDeleteModal(false)
        fetchfaqData()
      }
      
    } catch (error) {
      toast.error("Error while deleting faq please try again")
    }
  }

  useEffect(() => {
    fetchfaqData();
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end">
        <button
          className="basic-button"
          onClick={() => {
            setModalAction("add");
            handler();
          }}
        >
          Add New Faq
        </button>
      </div>
      {faqConfigData && (
        <>
          {faqConfigData.length > 0 &&
            faqConfigData?.map((data, index) => (
              <Accordion defaultActiveKey="0" key={index} className="mt-3">
                <Accordion.Item eventKey={index}>
                  <Accordion.Header className="row">
                    <div className="col-10">
                      <b>{data.question}</b>
                    </div>
                    <div className="col-1">
                      <img
                        src="/images/edit.svg"
                        alt="Edit Icon"
                        className="bg-white"
                        onClick={() => {
                          setFaqIndex(index);
                          setModalAction("update");
                          handler();
                        }}
                      />
                      <img
                        src="/images/trash-03.png"
                        alt=""
                        style={{ height: "3vh", marginLeft: "20px" }}
                        role="button"
                        onClick={() => {
                          setDeleteModal(true);
                          setDeleteIndex(index);
                        }}
                      />
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>{data.answer}</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
        </>
      )}
      <FaQModal
        openModal={openModal}
        handler={handler}
        faqIndex={faqIndex}
        faqConfigData={faqConfigData}
        actionType={modalAction}
      />

      <Modal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header>
          <b>Delete Faq #{deleteIndex + 1}</b>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              setDeleteModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>Are you sure want to delete the Faq ?</Modal.Body>
        <Modal.Footer>
          <button className="basic-button" onClick={handleDeleteFaq}>
            Delete Faq
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Faqs;
