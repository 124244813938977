import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  createInstituteFaq,
  updateInstituteFaq,
} from "../../../../helpers/services/websiteServices";
import toast from "react-hot-toast";

const FaqModal = ({ openModal, setOpenModal, faqdata, actionType, id , fetchData }) => {
  const [data, setData] = useState({
    question: faqdata?.question || "",
    answer: faqdata?.answer || "",
  });

  const [loading, setLoading] = useState(false);

  const handler = () => {
    clearData();
    setOpenModal(false);
  };

  const clearData = () => {
    setData({
      question: faqdata?.question || "",
      answer: faqdata?.answer || "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    let datas = {
      institution_id: id,
      question: data?.question,
      answer: data?.answer,
    };

    setLoading(true);

    if (actionType === "create") {
      const response = await createInstituteFaq(datas);
      if (response.status == "success") {
        toast.success("Faq Created");
        fetchData()
        clearData();
        handler();
        setLoading(false);
      } else {
        toast.error(response.data.errors);
        setLoading(false);
      }
    } else if (actionType == "update") {
      datas.id = faqdata.id;
      const response = await updateInstituteFaq(datas);
      if (response.status == "success") {
        toast.success("Faq Created");
        fetchData()
        clearData();
        handler();
        setLoading(false);
      } else {
        toast.error(response.data.errors);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => {
          handler();
          clearData();
        }}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>{actionType} FAq </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={handler}
          />
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form_control_box">
              <label className="form-label" htmlFor="question">
                Faq Question
              </label>
              <input
                type="text"
                id="question"
                name="question"
                placeholder="Enter Question"
                value={data?.question}
                className="form-control"
                onChange={handleChange}
              />
            </div>
            <div className="form_control_box">
              <label className="form-label" htmlFor="answer">
                Faq Answer
              </label>
              <textarea
                type="text"
                id="answer"
                name="answer"
                placeholder="Enter answer"
                className="form-control"
                value={data?.answer}
                onChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!loading ? (
            <>
              <button className=" basic-button" onClick={handleSubmit}>
                {" "}
                {actionType == "create" ? "Add Faq" : "Update Faq"}{" "}
              </button>
            </>
          ) : (
            <>
              <button className=" basic-button" disabled={true}>
                {" "}
                {actionType == "create" ? "Adding...." : "Updating...."}{" "}
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FaqModal;
