import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import { Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
  addEmployee,
  fetchEmployeesData,
  fetchProperties,
  listOfRoles,
  updateEmployeStatus,
} from "../../helpers/services/api.services";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import styles from "../revenue/revenueManualPage/modals/UploadAdjustmentModal/index.module.scss";
import moment from "moment";
import UpdateEmployee from "./UpdateEmployee";

const jobTitle = [
  { labal: "Customer Care", value: "Customer Care" },
  { labal: "Warden", value: "Warden" },
  { labal: "Ops Head", value: "Ops Head" },
  { labal: "Sales Head", value: "Sales Head" },
  { labal: "Admin", value: "Admin" },
  { labal: "Sales Manager", value: "Sales Manager" },
  { labal: "Cluster Manager", value: "Cluster Manager" },
  { labal: "Property Manager", value: "Property Manager" },
  { labal: "Marketing", value: "Marketing" },
  { labal: "Finance", value: "Finance" },
];

export const activateBtn = {
  fontSize: "12px",
  fontWeight: 800,
  textAlign: "center",
  borderRadius: "8px",
  padding: "5px 15px",
  border: "none",
};

function Employe() {
  // Data-table
  const [employees, setemployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeData, setEmployeData] = useState(null);
  const [serachObject, setSerachObject] = useState({});
  const [sortObject, setSortObject] = useState({});
  const [filterAcitveCount, setFilterAcitveCount] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [jobTitleApi, setjobTitle] = useState([]);

  const [filteredEmployees, setfilteredEmployees] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [operation, setOperation] = useState("Create");
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [showCreateEmployeeModal, setshowCreateEmployeeModal] = useState(false);
  const handleCloseEmployeecModal = () => {
    formik.resetForm();
    setshowCreateEmployeeModal(false);
  };
  const handleEmployeeModal = () => setshowCreateEmployeeModal(true);

  const loadData = async (serachObject, sortObject) => {
    setLoading(true);

    let response = await fetchEmployeesData(serachObject, sortObject);

    if (response && response.data && response.data.length > 0) {
      const filteredData = response?.data?.filter(
        (employe) => employe.is_deleted != true
      );
      const sortedData = filteredData.sort((a, b) => a.id - b.id);
      setemployees(sortedData);
      setLoading(false);
      handleCloseFilterModal();
    } else {
      setemployees([]);
      setLoading(false);
      handleCloseFilterModal();
    }
  };

  const columns = [
    {
      name: "EMP ID",
      selector: (row) => row?.employee_code,
      width: "10%",
      sortable: true,
    },
    {
      name: "Name",
      width: "20%",
      selector: (row) => row.employee_first_name,
      sortable: true,
    },
    {
      name: "Email",
      width: "25%",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Created at",
      selector: (row) => row.is_active,
      sortable: true,
      cell: (row) => moment(row?.created_at).format("DD-MM-YYYY") || "--",
    },
    {
      name: "IS_ACTIVE",
      selector: (row) => row.is_active,
      sortable: true,
      cell: (row) => (row.is_active ? "YES" : "NO"),
    },
    {
      name: "Action",
      selector: (row) => row?.id,
      cell: (row) => (
        <button
          style={{ border: "none", backgroundColor: "white" }}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Action"
          onClick={() => {
            setShowActiveModal(true);
            setEmployeData(row);
          }}
        >
          <img src="/images/edit.svg" alt="Edit Icon" />
        </button>
      ),
    },
    {
      name: "",
      selector: (row) => row?.id,
      // width: "3%",
      cell: (row) => (
        <button
          style={activateBtn}
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Edit Employee"
          onClick={() => {
            setShowUpdateModal(true);
            setEmployeData(row);
          }}
        >
          Edit
        </button>
      ),
    },
  ];

  const handleChangeStatus = async () => {
    const response = await updateEmployeStatus(
      employeData?.id,
      !employeData?.is_active
    );

    if (response?.status == "success") {
      toast.success(
        `Employee ${
          response.data.is_active ? "activated" : "deactivated"
        } successfully`
      );
    } else { 
      toast.error(`${response.data.errors[0]}`);
    }
    loadData();
  };

  const initialValues = {
    email: "",
    employee_first_name: "",
    employee_last_name: "",
    mobile_number: "",
    job_title: "",
    salary: 0,
    department: "",
    employee_code: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    employee_first_name: Yup.string().required("First Name is required"),
    employee_last_name: Yup.string(),
    mobile_number: Yup.string().required("Mobile Number is required"),
    salary: Yup.number().required("Salary is required"),
    job_title: Yup.string(),
    department: Yup.string(),
    employee_code: Yup.string().required("employee_code is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await addEmployee(values);
        if (response.status == "success") {
          toast.success("Employee created");
          handleCloseEmployeecModal();
          loadData();
        } else {
          toast.error(JSON.stringify(response.data));
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    const filtereddata = employees.filter(
      (employee) =>
        employee?.employee_code
          ?.toLowerCase()
          .toString()
          .includes(searchQuery.toLowerCase().trim()) ||
        employee?.email
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase().trim()) ||
        employee?.employee_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase().trim())
    );
    setfilteredEmployees(filtereddata);
  }, [searchQuery, employees]);

  const filterinitialValues = {
    email: "",
    name: "",
    property_id: "",
    role_type: "",
  };

  const filtervalidationSchema = null;

  const filterFormik = useFormik({
    initialValues: filterinitialValues,
    validationSchema: filtervalidationSchema,
    onSubmit: async (values) => {
      let search_obj = JSON.parse(JSON.stringify(values));
      const has_value = Object.values(values).some((val) =>
        val ? true : false
      );
      Object.keys(values).map((key) => {
        if (!values[key]) {
          delete search_obj[key];
        }
      });
      if (has_value) {
        setSerachObject(search_obj);
        setFilterAcitveCount(Object.keys(search_obj).length);
        loadData(search_obj, sortObject);
      }
    },
  });

  const handleClickFilterClose = (e) => {
    e.stopPropagation();
    setSerachObject({});
    setFilterAcitveCount(0);
    filterFormik.resetForm();
    loadData();
  };

  const handleResetFilter = ()=>{
    filterFormik.resetForm()

  }

  useEffect(() => {
    loadData(serachObject, sortObject);
  }, [perPage]);

  const loadProperty = async () => {
    const response = await fetchProperties(1, {} , {} , 1000);
    if (response && response?.status === "success") {
      let cities = response?.data?.map(element => {
        return { value: element?.id, label: element?.property_name }
      });
      const sortedList = cities?.sort((a, b) => a.label.localeCompare(b.label));
      setPropertyList(sortedList);
    }
  };

  const fetch_roles_list = async () => {
    try {
      const response = await listOfRoles();
      if (response.data) {
        const roles = response.data;
        const convertedRoles = roles.map((role) => ({
          label: role,
          value: role,
        }));
        setjobTitle((prevJobTitles) => [...prevJobTitles, ...convertedRoles]);
      }
    } catch (error) {
      console.error("Error fetching employee roles:", error);
    }
  };

  useEffect(() => {
    loadProperty();
    fetch_roles_list()
  }, []);

  const handleCloseFilterModal = () => setShowFilterModal(false);
  const handleShowFilterModal = () => {
    if (Object.keys(serachObject).length) {
      Object.keys(serachObject).map((key) => {
        filterFormik.setFieldValue(key, serachObject[key]);
      });
    }
    setShowFilterModal(true);
  };

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Employee List</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Masters</a> <span>/</span>
              </li>
              <li>EmployeeList</li>
            </ul>
          </div>
        </div>
        <div className="create-prop">
          <button
            className="basic-button"
            onClick={() => {
              setOperation("Create");
              handleEmployeeModal();
            }}
          >
            <img src="/images/plus-circle.svg" className="pr-2" alt="" />
            Add New Employee
          </button>
        </div>
      </div>

      {showUpdateModal && (
        <UpdateEmployee
          setShowUpdateModal={setShowUpdateModal}
          showUpdateModal={showUpdateModal}
          employeeData={employeData}
          loadData={loadData}
        />
      )}

      <div className="d-flex justify-content-end align-items-center ">
        <div className="status-box">
          <a
            onClick={handleShowFilterModal}
            className="cursor-pointor"
            style={{
              display: "flex",
              direction: "row",
            }}
          >
            <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
            {filterAcitveCount > 0 ? (
              <>
                <div
                  style={{
                    width: 22,
                    height: 22,
                    backgroundColor: "#FF5700",
                    borderRadius: "50%",
                    marginLeft: 4,
                    color: "#FFF4ED",
                    textAlign: "center",
                    justifyContent: "center",
                    font: "Manrope",
                    fontSize: 11,
                    fontStyle: "normal",
                    fontWeight: 800,
                    letterSpacing: "0.88px",
                  }}
                >
                  {filterAcitveCount}
                </div>
                <button
                  style={{
                    background: "none",
                    border: "none",
                  }}
                  onClick={handleClickFilterClose}
                >
                  <img
                    src="/images/close_with_bg.svg"
                    className="pr-1"
                    alt=""
                  />
                </button>
              </>
            ) : null}
          </a>
        </div>
      </div>

      {/* <div className="form_control_box">
        <Form.Control
          placeholder="Search by name or id"
          id="search"
          style={{ borderColor: "#d5d5d5" }}
          name="search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div> */}

      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={filteredEmployees.slice(
            (currentPage - 1) * perPage,
            currentPage * perPage
          )}
          pagination
          paginationServer
          progressPending={loading}
          fixedHeader={true}
          fixedHeaderScrollHeight={"440px"}
          paginationPerPage={perPage}
          paginationTotalRows={filteredEmployees.length}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPerPage(currentRowsPerPage);
            setCurrentPage(currentPage);
          }}
          paginationDefaultPage={currentPage}
          onChangePage={(page) => setCurrentPage(page)}
        />
      </div>

      <Modal
        show={showActiveModal}
        onHide={() => {
          setShowActiveModal(false);
          setEmployeData(null);
        }}
        backdrop="static"
        dialogClassName={styles["dialog-container"]}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={styles["#modal-title"]}>CHANGE STATUS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles["body-content"]}>
            <div>
              <p className={"mb-0 " + styles["label-title"]}>
                Are you sure you want{" "}
                {employeData?.is_active ? "deactivate" : "activate"}?
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn " + styles["btn-cancel"]}
            onClick={() => {
              setShowActiveModal(false);
              setEmployeData(null);
            }}
          >
            CANCEL
          </button>
          <button
            className={"btn " + styles["btn-submit"]}
            onClick={() => {
              handleChangeStatus();
              setShowActiveModal(false);
            }}
          >
            {employeData?.is_active ? "DEACTIVATE" : "ACTIVATE"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCreateEmployeeModal}
        onHide={handleCloseEmployeecModal}
        centered
        size="sm"
        className="confirm_noc_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal create-prop_modal">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 className="title">Create Employee</h2>
              </div>
              <div style={{ cursor: "pointer" }}>
                <span onClick={handleCloseEmployeecModal} className="">
                  <img src="/images/cross_modal.svg" alt="Close Icon" />
                </span>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
            <div className="create-prop_detail">
              <form onSubmit={formik.handleSubmit}>
                <div className="TrailWrap mb-4">
                  <div>
                    <div>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder="Enter Email"
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={(e) =>
                          formik.setFieldValue("email", e.target.value)
                        }
                        style={
                          formik.touched.email && formik.errors.email
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="error-label">{formik.errors.email}</div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        placeholder="Enter First Name"
                        id="employee_first_name"
                        name="employee_first_name"
                        value={formik.values.employee_first_name}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "employee_first_name",
                            e.target.value
                          )
                        }
                        style={
                          formik.touched.employee_first_name &&
                          formik.errors.employee_first_name
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.employee_first_name &&
                      formik.errors.employee_first_name ? (
                        <div className="error-label">
                          {formik.errors.employee_first_name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        placeholder="Enter Last Name"
                        id="employee_last_name"
                        name="employee_last_name"
                        value={formik.values.employee_last_name}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "employee_last_name",
                            e.target.value
                          )
                        }
                        style={
                          formik.touched.employee_last_name &&
                          formik.errors.employee_last_name
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.employee_last_name &&
                      formik.errors.employee_last_name ? (
                        <div className="error-label">
                          {formik.errors.employee_last_name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        placeholder="Enter Mobile Number"
                        id="mobile_number"
                        name="mobile_number"
                        value={formik.values.mobile_number}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "mobile_number",
                            e.target.value.trim()
                          )
                        }
                        style={
                          formik.touched.mobile_number &&
                          formik.errors.mobile_number
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.mobile_number &&
                      formik.errors.mobile_number ? (
                        <div className="error-label">
                          {formik.errors.mobile_number}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* <div>
                    <div>
                      <Form.Label>Salary</Form.Label>
                      <Form.Control
                        placeholder="Enter Salary"
                        id="salary"
                        name="salary"
                        value={formik.values.salary}
                        onChange={(e) =>
                          formik.setFieldValue("salary", e.target.value)
                        }
                        style={
                          formik.touched.salary && formik.errors.salary
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.salary && formik.errors.salary ? (
                        <div className="error-label">
                          {formik.errors.salary}
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                  <div>
                    <div>
                      <Form.Label>Job Title</Form.Label>
                      <Form.Select
                        placeholder="Select Job Title"
                        id="job_title"
                        isInvalid={
                          formik.touched.job_title && formik.errors.job_title
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.job_title}
                        aria-label="Default select example"
                      >
                        <option value="">Select Job Title</option>
                        {jobTitle.map((category) => (
                          <>
                            <option value={category.value} key={category.value}>
                              {category.labal}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                      {formik.touched.job_title && formik.errors.job_title ? (
                        <div className="error-label">
                          {formik.errors.job_title}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div>
                    <div>
                      <Form.Label>Department</Form.Label>
                      <Form.Control
                        placeholder="Enter Department"
                        id="department"
                        name="department"
                        value={formik.values.department}
                        onChange={(e) =>
                          formik.setFieldValue("department", e.target.value)
                        }
                        style={
                          formik.touched.department && formik.errors.department
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.department && formik.errors.department ? (
                        <div className="error-label">
                          {formik.errors.department}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div>
                    <div>
                      <Form.Label>Employee Code</Form.Label>
                      <Form.Control
                        placeholder="Enter employee code"
                        id="employee_code"
                        name="employee_code"
                        value={formik.values.employee_code}
                        onChange={(e) =>
                          formik.setFieldValue("employee_code", e.target.value)
                        }
                        style={
                          formik.touched.employee_code &&
                          formik.errors.employee_code
                            ? {
                                borderColor: "red",
                              }
                            : null
                        }
                      />
                      {formik.touched.employee_code &&
                      formik.errors.employee_code ? (
                        <div className="error-label">
                          {formik.errors.employee_code}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="orange_brd_button p-12-16-button mr-3"
                    onClick={() => handleCloseEmployeecModal()}
                  >
                    CANCEL
                  </button>
                  <button type="submit" className="basic-button">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Filter Modal */}

      <Modal
        show={showFilterModal}
        onHide={handleCloseFilterModal}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={handleCloseFilterModal}
          />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={filterFormik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyName">
                  Employee Name
                  </label>
                  <input
                    type="text"
                    id="residentName"
                    name="residentName"
                    placeholder="Enter Name"
                    className="form-control"
                    value={filterFormik.values.name}
                    onChange={(e) =>
                      filterFormik.setFieldValue("name", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyName">
                    Employee Email
                  </label>
                  <input
                    type="email"
                    id="emplyeeemail"
                    name="email"
                    placeholder="Enter email"
                    className="form-control"
                    value={filterFormik.values.email}
                    onChange={(e) =>
                      filterFormik.setFieldValue("email", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                <Form.Label>Property</Form.Label>
                <Form.Select
                  placeholder="Select City"
                  id="property_id"
                  onChange={filterFormik.handleChange}
                  onBlur={filterFormik.handleBlur}
                  value={filterFormik.values.property_id}
                  aria-label="Default select example">
                  <option value="">Select Property </option>
                  {
                    propertyList.map(city => <>
                      <option value={city.value}>{city.label}</option>
                    </>)
                  }
                </Form.Select>
              </div>
              <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                <Form.Label>Role Type</Form.Label>
                <Form.Select
                  placeholder="Select City"
                  id="role_type"
                  onChange={filterFormik.handleChange}
                  onBlur={filterFormik.handleBlur}
                  value={filterFormik.values.role_type}
                  aria-label="Default select example">
                  <option value="">Select Job Title </option>
                  {
                    jobTitleApi.map(city => <>
                      <option value={city.value}>{city.label}</option>
                    </>)
                  }
                </Form.Select>
              </div>

              <div className="modal_btn_footer mt-4">
                <button
                  type="button"
                  className="orange_brd_button p-12-16-button mr-3"
                  style={{ width: "fit-content" }}
                  onClick={() => handleResetFilter()}
                >
                  RESET
                </button>
                <button type="submit" className={"basic-button"}>
                  SEARCH
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withDefaultDashBoardLayout(Employe);
