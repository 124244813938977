import axios from "axios";
import  secureLocalStorage  from  "react-secure-storage";

const createLogger = () => {
  const start = (taskName = "") => {
    const s = new Date().getTime();
    const end = () => {
      const e = new Date().getTime();
      const timeTakenInMs = Math.round(e - s);
      const timeTaken =
        timeTakenInMs > 1000 ? Math.round(timeTakenInMs / 1000) : timeTakenInMs;
      if (
        process.env.NODE_ENV === "development" &&
        typeof window != "undefined"
      ) {
        console.warn(
          "\n\nDebug: " +
            taskName +
            "\nTime: %d" +
            `${timeTakenInMs > 1000 ? "s" : "ms"}`,
          timeTaken
        );
      }
    };
    return end;
  };

  return {
    start,
  };
};

const axiosService = (
  url = "",
  method = "GET",
  body = null,
  headers = {},
  config = {}
) => {
  const extraConfig = typeof config === "object" ? config : {};
  const options = {
    url,
    method: method,
    data: body,
    headers: {
      Authorization: `Bearer ${secureLocalStorage.getItem("accessToken")}`,
      ...headers
    },
  };
  if (
    method.toLowerCase() === "get" &&
    options.data &&
    typeof options.data === "object"
  ) {
    options.params = options.data;
    delete options.data;
  }

  axios.interceptors.request.use((x) => {
    x.meta = x.meta || {};
    x.meta.requestStartedAt = new Date().getTime();
    return x;
  });

  axios.interceptors.response.use(
    (x) => {
      return x;
    },
    (x) => {
      throw x;
    }
  );
  const apiLog = `[${method}] ${url}`;
  const logger = createLogger();
  const logEnd = logger.start(apiLog);
  return axios({ withCredentials: false, ...options, ...extraConfig })
    .then((res) => {
      logEnd();
      if (res.status >= 200 && res.status < 300) {
        return { ...res, data: res.data };
      } else {
        throw { status: res.status, data: res.data };
      }
    })
    .catch((e) => {
      logEnd();
      if (
        typeof e === "object" &&
        typeof e.response === "object" &&
        e.response?.status
      ) {
        // eslint-disable-next-line no-throw-literal
        throw { status: e.response.status, data: e.response.data };
      } else {
        console.error(e.response);
        // eslint-disable-next-line no-throw-literal
        throw {
          status: 532,
          data: "Internet connection failure or server is down",
        };
      }
    });
};

function HttpGet(url, params = null, headers = {}, config = {}) {
  return axiosService(url, "GET", params, headers, config);
}

function HttpPost(url, body, headers = {}, config = {}) {
  return axiosService(url, "POST", body, headers, config);
}

function HttpPut(url, body, headers = {}, config = {}) {
  return axiosService(url, "PUT", body, headers, config);
}

function HttpPatch(url, body, headers = {}, config = {}) {
  return axiosService(url, "PATCH", body, headers, config);
}

function HttpDelete(url, body ,headers = {}, config = {}) {
  return axiosService(url, "DELETE", body, headers, config);
}

const apiClient = {
  get: HttpGet,
  post: HttpPost,
  put: HttpPut,
  patch: HttpPatch,
  delete: HttpDelete,
};

export default apiClient;
