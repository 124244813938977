import React from "react";
import { Box } from "@mui/material";

const Loader = () => {
  return (
    <div
      className="App"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        background: "#FF5700",
        zIndex: "99999999",
      }}
    >
      <Box>
      <img
        src="/images/loader-ys.gif"
        alt="Loader"
        width={200}
        height={200}
      />
    </Box>
    </div>
  );
};

export default Loader;