import { Outlet, Navigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import toast from 'react-hot-toast';
import { useEffect } from 'react';

const PrivateRoutes = () => {
  const accessToken = secureLocalStorage.getItem("accessToken");
  const sessionExpiration = secureLocalStorage.getItem("sessionExpiration");

  const currentTime = new Date().getTime();

  useEffect(()=> {
    if(!sessionExpiration){
        secureLocalStorage.removeItem("accessToken");
        secureLocalStorage.removeItem("roles");
        secureLocalStorage.removeItem("sessionExpiration");
        <Navigate to="/" />
    }
  },[])

  if (accessToken) {
    if (sessionExpiration && currentTime > sessionExpiration) {
      toast.error("Session expired. Please log in again.");
      secureLocalStorage.removeItem("accessToken");
      secureLocalStorage.removeItem("roles");
      secureLocalStorage.removeItem("sessionExpiration");
      return <Navigate to="/" />;
    }
    return <Outlet />;
  }

  // If no access token, redirect to login page
  return <Navigate to="/" />;
};

export default PrivateRoutes;
