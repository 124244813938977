import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { updateInstitutionDetails } from "../../../../helpers/services/websiteServices";

const UpdateProperty = ({
  instituteId,
  openModal,
  setOpenModal,
  data,
  fetchData,
}) => {
  const [instituteData, setInstituteData] = useState({
    name: data?.name || "",
    institute_title: data?.institute_data?.institute_title || "",
    institute_description: data?.institute_data?.institute_description || "",
    meta_title: data?.meta_data?.meta_title || "",
    meta_description: data?.meta_data?.meta_description || "",
    meta_keywords: data?.meta_data?.meta_keywords || "",
    meta_image: data?.meta_data?.meta_image || "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInstituteData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const updateData = async () => {
    setLoading(true);
    const updatedData = {
      name: instituteData.name,
      institute_data: {
        institute_title: instituteData.institute_title,
        institute_description: instituteData.institute_description,
      },
      meta_data: {
        meta_title: instituteData.meta_title,
        meta_description: instituteData.meta_description,
        meta_keywords: instituteData.meta_keywords,
        meta_image: instituteData.meta_image,
      },
    };

    const response = await updateInstitutionDetails(instituteId, updatedData);
    if (response.status === "success") {
      toast.success("Data updated successfully");
      fetchData();
      setLoading(false);
      handleClose();
    } else {
      toast.error("Error updating data, please try again");
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={handleClose}
        centered
        size="lg"
        className="common_modal"
      >
        <Modal.Header>
          <Modal.Title className="underline">{data?.name}</Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="form_control_box">
            <div className="mb-2">
              <label htmlFor="name" className="form-label">
                <b>Name</b>
              </label>
              <input
                type="text"
                id="name"
                className="form-control mt-1"
                name="name"
                onChange={handleChange}
                value={instituteData.name}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="institute_title" className="form-label">
                <b>Institute Title</b>
              </label>
              <input
                type="text"
                id="institute_title"
                className="form-control mt-1"
                name="institute_title"
                onChange={handleChange}
                value={instituteData.institute_title}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="institute_description" className="form-label">
                <b>Institute Description</b>
              </label>
              <textarea
                id="institute_description"
                className="form-control mt-1"
                name="institute_description"
                onChange={handleChange}
                value={instituteData.institute_description}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="meta_title" className="form-label">
                <b>Meta Title</b>
              </label>
              <input
                type="text"
                id="meta_title"
                className="form-control mt-1"
                name="meta_title"
                onChange={handleChange}
                value={instituteData.meta_title}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="meta_description" className="form-label">
                <b>Meta Description</b>
              </label>
              <textarea
                id="meta_description"
                className="form-control mt-1"
                name="meta_description"
                onChange={handleChange}
                value={instituteData.meta_description}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="meta_keywords" className="form-label">
                <b>Meta Keywords</b>
              </label>
              <input
                type="text"
                id="meta_keywords"
                className="form-control mt-1"
                name="meta_keywords"
                onChange={handleChange}
                value={instituteData.meta_keywords}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="meta_image" className="form-label">
                <b>Meta Image</b>
              </label>
              <input
                type="text"
                id="meta_image"
                className="form-control mt-1"
                name="meta_image"
                onChange={handleChange}
                value={instituteData.meta_image}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="orange_brd_button" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="basic-button"
            onClick={updateData}
            disabled={loading}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateProperty;
