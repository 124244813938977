import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateImage } from "../../helpers/services/websiteServices";
import toast from "react-hot-toast";

const DeleteImageModal = ({ openDeleteModal, setDeleteModal, data , fetchData }) => {
  const [loding, setLoading] = useState(false);

  const deleteData = async () => {
    setLoading(true);

    const response = await updateImage(data);

    if (response?.status == "success") {
      toast.success("Image Deleted");
      fetchData()
      setDeleteModal(false);
      setLoading(false);
    } else {
      toast.error(`${response.data.error}`);
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal show={openDeleteModal} centered size="md" className="common_modal">
        <Modal.Header>
          <b>Delete Image</b>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              setDeleteModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>Are you sure want to delete the Image ?</Modal.Body>
        <Modal.Footer>
          <button
            className="basic-button"
            disabled={loding}
            onClick={deleteData}
          >
            Delete Image
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteImageModal;
