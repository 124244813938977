import { useContext, useEffect } from "react";
import "../../../css/resident-details-tab.css";
import { useState } from "react";
import moment from "moment";
import { downloadProformaInvoice } from "../../../helpers/services/api.services";
import "../../../css/loader.css";
import GlobalStateContext from "../../../GlobalStateContext";
import { Modal } from "react-bootstrap";
import { updateCollection } from "../../../helpers/services/admin.services";
import toast from "react-hot-toast";

function ScheduleTab({ residentInfo, residentOnboardingInfo , loadResidentsDetails }) {
  const { roles } = useContext(GlobalStateContext);
  const [contractDetails, setContractDetails] = useState(null);
  const [basicDetails, setBasicDetails] = useState(null);
  const [collection_schedule_list, setCollection_schedule_list] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [downloadLoading, setDownloading] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState({
    id : "",
    corrected_amount: "",
    late_fee_amount: "",
    operational_status: "",
    payment_status: "",
    knockoff: "true",
  });

  const operationalStatusOptions = [
    'out_standing' ,'created', 'over_due', 'od_late_fee', 'od_wifi_disconnect',
    'od_laundary_disconnect', 'od_food_disconnect', 'od_legal', 'od_eviction', 
    'payment_cancelled', 'late_fee_hold'
  ];

  const paymentStatusOptions = [ 'pending' , 'todo', 'cancelled'];

  const handleCloseUpdateModal = ()=>{
      setUpdateData({
        id : "",
        corrected_amount: "",
        late_fee_amount: "",
        operational_status: "",
        payment_status: "",
        knockoff: "false",
      })
      setOpenUpdateModal(false)
  }

  const handleUpdateSubmit = async() => {

    const formData = new FormData()

    try {

      formData.append("corrected_amount" , updateData.corrected_amount)
      formData.append("late_fee_amount" , updateData.late_fee_amount)
      formData.append("operational_status" , updateData.operational_status)
      formData.append("payment_status" , updateData.payment_status)
      formData.append("knockoff" , updateData.knockoff)

      const response =  await updateCollection(updateData.id , formData)

      if(response.status == "error"){
        return toast.error(response.errors)
      }else if(response.status == "success"){
        toast.success(response.data)
        loadResidentsDetails()
        handleCloseUpdateModal()
      }
      
    } catch (error) {
      console.log(error)
    }
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const finYear = `FY${moment().format("YY")}${moment()
    .add(1, "year")
    .format("YY")}`;

  useEffect(() => {
    if (residentInfo && residentInfo?.contract_details)
      setContractDetails(residentInfo?.contract_details);
    if (residentInfo && residentInfo?.basic_details)
      setBasicDetails(residentInfo?.basic_details);
  }, [residentInfo, residentOnboardingInfo]);

  const handleDownloadPerformaInvoice = async (id) => {
    try {
      setDownloading(true);

      const response = await downloadProformaInvoice(id);

      const link = document.createElement("a");
      link.href = response.data.s3_url;
      link.target = "_blank";
      link.download = `proforma_invoice_${id}.pdf`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedId(null);
      setDownloading(false);
    }
  };

  useEffect(() => {
    if (
      residentOnboardingInfo &&
      residentOnboardingInfo.collection_schedule.length > 0
    ) {
      // const sorted_data = residentOnboardingInfo.collection_schedule.sort(
      //   (a, b) => {
      //     const dateA = new Date(a.due_date);
      //     const dateB = new Date(b.due_date);

      //     if (dateA < dateB) return -1;
      //     if (dateA > dateB) return 1;

      //     return a.id - b.id;
      //   }
      // );

      setCollection_schedule_list(residentOnboardingInfo.collection_schedule);
    }
  }, [residentOnboardingInfo]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="brd_box_card">
            <div className="box_head">
              <h2>
                {" "}
                <img src="/images/bank-note.svg" className="pr-2" alt="" />{" "}
                revenue summary
              </h2>
            </div>

            <div className="box_contain">
              <div className="d-flex justify-content-between  box_card_data">
                <h3>Contract Duration (M)</h3>
                <i>:</i>
                <span>{contractDetails?.duration ?? "--"}</span>
              </div>
              <div className="d-flex justify-content-between  box_card_data">
                <h3>Rent instalment</h3>
                <i>:</i>
                <span>{contractDetails?.rent_charges ?? "--"}</span>
              </div>
              {/* <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Paid By *</h3>
                                <i>:</i>
                                <span>{basicDetails?.resident_name ?? '--'}</span>
                            </div>
                            <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Is B2B</h3>
                                <i>:</i>
                                <span>NTD</span>
                            </div> */}
              <div className="d-flex justify-content-between  box_card_data">
                <h3>Last date of residence</h3>
                <i>:</i>
                <span>{contractDetails?.contract_end_date ?? "--"}</span>
              </div>
              <div className="d-flex justify-content-between  box_card_data">
                <h3>Contract value</h3>
                <i>:</i>
                <span>{contractDetails?.contract_value ?? "--"}</span>
              </div>
              <div className="d-flex justify-content-between  box_card_data">
                <h3>YTD collected</h3>
                <i>:</i>
                <span>{contractDetails?.ytd_collected ?? "--"}</span>
              </div>
              <div className="d-flex justify-content-between  box_card_data">
                <h3>YTD Booked</h3>
                <i>:</i>
                <span>{contractDetails?.ytd_booked ?? "--"}</span>
              </div>
              <div className="d-flex justify-content-between  box_card_data">
                <h3>YTD Deferred</h3>
                <i>:</i>
                <span>{contractDetails?.ytd_deffered ?? "--"}</span>
              </div>
            </div>
            <div className="box_footer">
              {/* <div className="file-upload">
                                <label htmlFor="upload" className="file-upload__label">UPLOAD POST ADJUSTMENT ENTRIES</label>
                                <input id="upload" className="file-upload__input" type="file" name="file-upload" />
                            </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="brd_box_card">
            <div className="box_head">
              <h2>
                <img src="/images/coins-stacked.svg" className="pr-2" alt="" />
                BALANCE - <span>{finYear}</span>
              </h2>
            </div>
            <div className="box_contain">
              <div className="d-flex justify-content-between  box_card_data">
                <h3>Wallet</h3>
                <i>:</i>
                <span>{residentOnboardingInfo?.balance?.wallet ?? 0}</span>
              </div>
              <div className="d-flex justify-content-between  box_card_data">
                <h3>SD</h3>
                <i>:</i>
                <span>{residentOnboardingInfo?.balance?.sd_amount ?? 0}</span>
              </div>
              {/* <div className='d-flex justify-content-between  box_card_data'>
                                <h3>Deferred</h3>
                                <i>:</i>
                                <span>N/A</span>
                            </div> */}
              <div className="d-flex justify-content-between  box_card_data">
                <h3>Late Fee</h3>
                <i>:</i>
                <span>{residentOnboardingInfo?.balance?.late_fee ?? 0}</span>
              </div>
              <div className="d-flex justify-content-between  box_card_data">
                <h3>Lien Amount</h3>
                <i>:</i>
                <span>{residentOnboardingInfo?.balance?.lien_amount ?? 0}</span>
              </div>
              {/* <div className='d-flex justify-content-between  box_card_data'>
                                <h3>OS</h3>
                                <i>:</i>
                                {
                                    residentOnboardingInfo?.balance?.os ? <span>
                                        <img src='/images/copy.svg' className='pr-1' alt='' />
                                        {residentOnboardingInfo?.balance?.os}
                                    </span> : <span>0</span>
                                }
                            </div> */}
              {/* <div className='d-flex justify-content-between  box_card_data'>
                                <h3>OD</h3>
                                <i>:</i>
                                <span>{residentOnboardingInfo?.balance?.od ?? 0}</span>
                            </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-32" id="collectionSchedule">
        <div className="table_main_tit pb-20">
          <h2>Collection Schedule</h2>
        </div>
        <div className="schedule_table commen_table">
          <div className="page-table-box">
            <table>
              <thead>
                <tr>
                  <th>Payment ID</th>
                  <th>Notes</th>
                  {/* <th>Schedule On</th> */}
                  <th>Collection Period</th>
                  <th>Amount</th>
                  <th>Late Fee</th>
                  <th>Due On</th>
                  <th>Proforma Invoice </th>
                  {roles?.super_admin && <th> Action </th>}
                </tr>
              </thead>
              <tbody>
                {residentOnboardingInfo?.collection_schedule &&
                residentOnboardingInfo?.collection_schedule.length > 0 &&
                collection_schedule_list.length > 0 ? (
                  collection_schedule_list?.map((item, index) => (
                    // <tr key={index}>
                    //     <td>{item?.payment_slug}</td>
                    //     <td>{item?.frequency}</td>
                    //     <td>N/A</td>
                    //     <td>{item?.due_date ? moment(item?.due_date).format('DD-MM-YYYY') : '--'}</td>
                    //     <td className='text-end'>{item?.gross_amount}</td>
                    // </tr>
                    <tr key={index}>
                      <td>{item?.id}</td>
                      <td>
                        {item?.payment_slug} / {item?.payment_status}
                      </td>
                      <td>
                        {item?.service_period_start_date
                          ? moment(item.service_period_start_date).format(
                              "DD-MM-YYYY"
                            )
                          : ""}
                        &nbsp;&nbsp;to&nbsp;&nbsp;
                        {item?.service_period_end_date
                          ? moment(item.service_period_end_date).format(
                              "DD-MM-YYYY"
                            )
                          : ""}
                      </td>
                      <td className="">{item?.gross_amount}</td>
                      <td className="">{item?.late_fee_amount}</td>
                      <td>
                        {item?.due_date
                          ? moment(item?.due_date).format("DD-MM-YYYY")
                          : "--"}
                      </td>
                      <td>
                        <div
                          onClick={() => {
                            if (!downloadLoading) {
                              handleDownloadPerformaInvoice(item?.id);
                              setSelectedId(item?.id);
                            }
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ color: "blue", cursor: "pointer" }}>
                            {item?.invoice_number}
                          </div>{" "}
                          <div>
                            {selectedId == item?.id && downloadLoading && (
                              <span class="loader"></span>
                            )}{" "}
                          </div>
                        </div>
                      </td>
                      {roles?.super_admin && (
                        <td
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => {
                            setUpdateData({
                              id : item.id,
                              corrected_amount: item?.gross_amount || 0,
                              late_fee_amount: item?.late_fee_amount || 0,
                              operational_status: item?.operational_status == "paid" ? "out_standing" :item?.operational_status,
                              payment_status: item?.payment_status == "success" ? "pending" : item?.payment_status  ,
                              knockoff: "true",
                            });
                            setOpenUpdateModal(true);
                          }}
                        >
                          {" "}
                          Update{" "}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No collection found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="pt-32">
        <div className="table_main_tit pb-20">
          <h2>Invoice Schedule</h2>
        </div>
        <div className="schedule_table commen_table">
          <div className="page-table-box">
            <table>
              <thead>
                <tr>
                  <th>Invoice Number / CN</th>
                  <th>Notes</th>
                  <th>invoice Date</th>
                  <th className="text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {residentOnboardingInfo?.invoice_details &&
                residentOnboardingInfo?.invoice_details.length > 0 ? (
                  residentOnboardingInfo?.invoice_details.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {" "}
                        <a href={item.tax_invoice_url} target="_blank">
                          {item?.invoice_number}
                        </a>{" "}
                      </td>
                      {/* <td>{item?.payment_slug} / {item?.invoice_reference_id}</td> */}
                      <td>
                        {item?.payment_slug} /{" "}
                        {item?.payment_slug === "credit_note"
                          ? item?.invoice_reference_number
                          : item?.invoice_reference_id}
                      </td>
                      <td>
                        {item?.invoice_date
                          ? moment(item.invoice_date).format("DD-MM-YYYY")
                          : "--"}
                      </td>
                      <td className="text-end">
                        {item?.gross_amount_with_late_fee}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>No invoice found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={openUpdateModal}
        onHide={handleCloseUpdateModal}
        centered
        size="md"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>Modify collection schedule</Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={handleCloseUpdateModal}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-6">
              <div className="form_control_box">
                <label className="form-label" htmlFor="corrected_amount">
                  Corrected Amount
                </label>
                <input
                  type="number"
                  id="corrected_amount"
                  name="corrected_amount"
                  placeholder="Corrected Amount"
                  className="form-control"
                  value={updateData.corrected_amount}
                  onChange={handleUpdateInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-6">
              <div className="form_control_box">
                <label className="form-label" htmlFor="late_fee_amount">
                  Late Fee Amount
                </label>
                <input
                  type="number"
                  id="late_fee_amount"
                  name="late_fee_amount"
                  placeholder="Late Fee Amount"
                  className="form-control"
                  value={updateData.late_fee_amount}
                  onChange={handleUpdateInputChange}
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_control_box">
                <label className="form-label" htmlFor="operational_status">
                  Operational Status
                </label>
                <select
                  id="operational_status"
                  name="operational_status"
                  className="form-control"
                  value={updateData.operational_status}
                  onChange={handleUpdateInputChange}
                >
                  {operationalStatusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form_control_box">
                <label className="form-label" htmlFor="payment_status">
                  Payment Status
                </label>
                <select
                  id="payment_status"
                  name="payment_status"
                  className="form-control"
                  value={updateData.payment_status}
                  onChange={handleUpdateInputChange}
                >
                  {paymentStatusOptions.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form_control_box">
                <label className="form-label" htmlFor="knockoff">
                  Knockoff
                </label>
                <select
                  id="knockoff"
                  name="knockoff"
                  className="form-control"
                  value={updateData.knockoff}
                  onChange={handleUpdateInputChange}
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="orange_brd_button p-12-16-button mr-3"
            onClick={handleCloseUpdateModal}
          >
            CANCEL
          </button>
          <button type="button" className="basic-button " onClick={handleUpdateSubmit}>
            UPDATE
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ScheduleTab;
