import React, { useContext } from 'react'
import { fetchOnboardingList } from '../../helpers/services/api.services';
import GlobalStateContext from '../../GlobalStateContext';
import { useNavigate } from 'react-router-dom';

export const NotificationIcon = () => {
  let [popupOpen, setPopupOpen] = React.useState(false);
  let {setActiveResidentStatus,setTabKey,roles,setSerachObject,verification_count,checkin_count} = useContext(GlobalStateContext);
  const navigate = useNavigate();


  const handleCheckinClick = () => {
    setPopupOpen(false);
    setActiveResidentStatus('Pending Check-Ins');
    setTabKey('checkin_tab');
    setSerachObject((prev) => ({ ...prev, status: 'checkin_tab' }));
    navigate('/onboarding');
  };

  const handleVerificationClick = () => {
    setPopupOpen(false);
    setActiveResidentStatus('Pending Assets Verification');
    setTabKey('verification_tab');
    setSerachObject((prev) => ({ ...prev, status: 'verification_tab' }));
    navigate('/onboarding');
  };

  const handleTicketClick = () => {
    setPopupOpen(false);
    navigate('/tickets');
  };


  // add custom css to the notification icon
  return (
    <>
      <div className="position-relative cursor-pointer"
        onClick={() => setPopupOpen(true)}
      >
        {
          checkin_count + verification_count > 0 && (
            <div className="notification-count"
              style={{
                position: 'absolute',
                top: '-5px',
                right: '-4px',
                fontSize: '12px',
                backgroundColor: 'red',
                fontWeight: 'bold',
                padding: '2px',
                color: 'white',
                borderRadius: '50%',
                width: '25px',
                height: '25px',
                textAlign: 'center',
                lineHeight: '20px'
              }}
            >
              {checkin_count + verification_count > 99 ? '99+' : checkin_count + verification_count}
            </div>
          )
        }
        <img src="/images/noti.png" alt="notification" width="40" height="40" style={{ borderRadius : "12px" }} />
      </div>
      {
        popupOpen && (
          <div className="notification-popup-overlay"
            onClick={() => setPopupOpen(false)}
          >
            <div className="notification-popup mob-notification-popup"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="notification-popup-header">
                <div className="notification-popup-title mob-notification-popup-title">Notifications</div>
                <div className="notification-popup-close" onClick={() => setPopupOpen(false)}><img src="/images/cross_modal.svg" alt="Close Icon" /></div>
              </div>
              <div className="notification-popup-body">
                {
                  checkin_count > 0 && (
                    <div className="notification-popup-item mob-notification-popup-item">
                      <div className="notification-popup-item-title mob-notification-popup-item-title">
                        {/* Total &nbsp;<b style={{ color: 'red' }} >{checkin_count}</b>&nbsp; Check-ins Pending */}
                        Pending Check-Ins - &nbsp;<b style={{ color: 'red' }} >{checkin_count}</b>&nbsp;
                      </div>
                      <button className="notification-popup-item-action mob-notification-popup-item-action"
                      onClick={() => handleCheckinClick()}
                      >
                        Review Now
                      </button>
                    </div>
                  )
                }
                {
                  verification_count > 0 && (
                    <div className="notification-popup-item mob-notification-popup-item">
                      <div className="notification-popup-item-title mob-notification-popup-item-title">
                         {/* Total &nbsp;<b style={{ color: 'red' }}  >{verification_count}</b>&nbsp; Assets Verification Pending */}
                         Pending Assets Verification - &nbsp;<b style={{ color: 'red' }}  >{verification_count}</b>&nbsp;
                      </div>
                      <button className="notification-popup-item-action mob-notification-popup-item-action"
                      onClick={() => handleVerificationClick()}
                      >
                        Verify Assets
                      </button>
                    </div>
                  )
                }
                {
                  checkin_count + verification_count <= 0 && (
                    <div className="notification-popup-item mob-notification-popup-item">
                      <div className="notification-popup-item-title mob-notification-popup-item-title">
                        No Notifications
                      </div>
                    </div>
                  )
                }
                <div className="notification-popup-item mob-notification-popup-item">
                      <div className="notification-popup-item-title mob-notification-popup-item-title">
                         {/* Total &nbsp;<b style={{ color: 'red' }}  >{verification_count}</b>&nbsp; Assets Verification Pending */}
                         Open/Closed Tickets Information
                      </div>
                      <button className="notification-popup-item-action mob-notification-popup-item-action"
                      onClick={() => handleTicketClick()}
                      >
                        View Tickets
                      </button>
                    </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}