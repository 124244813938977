import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { fetchProperties } from "../../helpers/services/api.services";
import { fetchClusters, fetchCities, fetchStates, fetchRoomTypes, fetchEmployees, fetchCommonAssets, fetchRoomAssets } from "../../helpers/services/admin.services";
import PropertyConstant from "../../helpers/constants/properties";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import DetailsTab from "../../components/tabs/create-property/details.tab";
import CreateBookingPlan from "../../components/tabs/create-property/booking.plan";
import OwnerKyc from "../../components/tabs/create-property/owner.kyc";
import CreateOperations from "../../components/tabs/create-property/operations";
import GlobalStateContext from "../../GlobalStateContext";
import { useFormik } from 'formik';
import { Form } from "react-bootstrap";

function FoodPropertiesList() {
  const navigate = useNavigate();
  const { roles } = useContext(GlobalStateContext);

  const [propertyStatus, setPropertyStatus] = useState([]);
  const [activePropertyStatus, setActivePropertyStatus] = useState("All");
  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [downloadPropertyId, setdownloadPropertyId] = useState(null);

  const [clusters, setClusters] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);

  const [emplyees, setEmployees] = useState([]);
  const [commonAssets, setCommonAssets] = useState([]);
  const [roomAssets, setRoomAssets] = useState([]);

  // Data-table
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [page, setPage] = useState(1);

  const [serachObject, setSerachObject] = useState({})
  const [sortObject, setSortObject] = useState({})
  const [filterAcitveCount, setFilterAcitveCount] = useState(0)
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleCloseFilterModal = () => setShowFilterModal(false);

  const handleShowFilterModal = () => {
    if (Object.keys(serachObject).length) {
      Object.keys(serachObject).map((key) => {
        formik.setFieldValue(key, serachObject[key])
      })
    }
    setShowFilterModal(true);
  }

  // Create Property Modal
  const handleCloseConfirmNocModal = () => setShowCreatePropertyModal(false);
  const handleCreatePropertyModal = () => setShowCreatePropertyModal(true);

  useEffect(() => {
    setPropertyStatus(PropertyConstant.propertyStatus);
    loadProperty(page, serachObject, sortObject, rowsPerPage);
    loadCities();
  }, []);

  const loadProperty = async (currentPage, searchPayload, sortPayload, current_rowPerPage) => {
    setLoading(true);

    let response = await fetchProperties(currentPage, searchPayload, sortPayload, current_rowPerPage);

    if (response && response?.status === "success" && response.data && response.data.length > 0) {
      setProperties(response.data);

      if (response?.meta && response?.meta?.total_data_count && response?.meta?.total_pages) {
        setTotalRows(response?.meta?.total_data_count);
      }
      setPage(currentPage);
      handleCloseFilterModal();
      setLoading(false);
    } else {
      setProperties([]);
      handleCloseFilterModal();
      setLoading(false);
    }
  };

  const filteredResidentsData = async (status) => {
    setActivePropertyStatus(status);
  };

  const handlePageChange = (page) => {
    loadProperty(page, serachObject, sortObject, rowsPerPage);
  };

  const columns = [
    {
      name: 'Property Name',
      selector: (row) => row.property_name,
      width: "95%",
      sortable: true,
      sortField: 'property_name'
    },
    {
      name: '',
      selector: (row) => row?.id,
      width: "3%",
      cell: row => (
        <Link to={`/food_verification/${row?.id}`}>
          <img src="/images/right_arrow.svg" alt="Details Icon" />
        </Link>
      )
    },
  ];

  const handleDetailsPageRedirection = (data) => {
    navigate(`/food_verification/${data?.id}`);
  }


  const handleOpenCreatePropertyModal = async () => {
    await loadClusters();
    await loadStates();
    await loadCities();
    await loadRoomTypes();
    await loadEmployees();
    await loadCommonAssets();
    await loadRoomAssets();
    await handleCreatePropertyModal();
  }

  const loadClusters = async () => {
    let response = await fetchClusters();

    if (response && response.data && response.data.length > 0) {
      let clusters = response.data.map(element => {
        return { value: element?.id, label: element?.cluster_name }
      });

      setClusters(clusters);
    }
  }

  const loadStates = async () => {
    let response = await fetchStates();

    if (response && response.data && response.data.length > 0) {
      let states = response.data.map(element => {
        return { value: element?.id, label: element?.name }
      });
      setStates(states);
    }
  }

  const loadCities = async () => {
    let response = await fetchCities();

    if (response && response.data && response.data.length > 0) {
      let cities = response.data.map(element => {
        return { value: element?.id, label: element?.city_name }
      });
      setCities(cities);
    }
  }

  const loadRoomTypes = async () => {
    let response = await fetchRoomTypes();

    if (response && response.data && response.data.length > 0) {
      let roomTypes = response.data.map(element => {
        return { value: element?.id, label: element?.name, bedsPerRoom: element?.beds_per_room }
      });

      setRoomTypes(roomTypes);
    }
  }

  const loadEmployees = async () => {
    let response = await fetchEmployees();
    if (response && response.data && response.data.length > 0) {
      let states = response.data.map(element => {
        return { value: element?.id, label: element?.name }
      });
      setEmployees(response.data);
    }
  }

  const loadCommonAssets = async () => {
    let response = await fetchCommonAssets();
    if (response && response.data && response.data.length > 0) {
      setCommonAssets(response.data);
    }
  }

  const loadRoomAssets = async () => {
    let response = await fetchRoomAssets();
    if (response && response.data && response.data.length > 0) {
      setRoomAssets(response.data);
    }
  }


  const handleSort = async (column, sortDirection) => {
    let sort_obj = {
      sort_by: column.sortField,
      sort_direction: sortDirection
    }

    if (column.sortField) {
      setSortObject(sort_obj)
      loadProperty(page, serachObject, sort_obj, rowsPerPage);
    }
  };


  const initialValues = {
    name: "",
    property_code: "",
    // capacity_count:"",
    city: "",
    city_id: "",
    // occupancy_count:"",
  }

  const validationSchema = null

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {

      let search_obj = JSON.parse(JSON.stringify(values))
      const has_value = Object.values(values).some(val => val ? true : false)
      Object.keys(values).map((key) => {
        if (!values[key]) {
          delete search_obj[key]
        }
      })
      if (has_value) {
        setSerachObject(search_obj);
        setFilterAcitveCount(Object.keys(search_obj).length)
        loadProperty(1, search_obj, sortObject, rowsPerPage);
      }
    },
  });

  const handleClickFilterClose = (e) => {
    e.stopPropagation()
    setSerachObject({})
    setFilterAcitveCount(0)
    formik.resetForm();
    loadProperty(1, {}, sortObject, rowsPerPage, rowsPerPage);
  }

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPage(currentPage)
    setRowsPerPage(currentRowsPerPage)
    loadProperty(currentPage, {}, sortObject, currentRowsPerPage);
  }

  const handleResetFilter = (e) => {
    formik.resetForm();
  }


  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Food Token Verification</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Food Token Verification</a> <span>/</span>
              </li>
              {/* <li>YS BLR - YS Banergatta</li>  */}
            </ul>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center pb-0 pb-md-3">
        <div className="status-box">
        </div>
        <div className="status-box">
          <a
            onClick={handleShowFilterModal}
            className="cursor-pointor"
            style={{
              display: "flex",
              direction: 'row'
            }}
          >
            <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
            {
              filterAcitveCount > 0 ?
                <>
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      backgroundColor: '#FF5700',
                      borderRadius: "50%",
                      marginLeft: 4,
                      color: '#FFF4ED',
                      textAlign: 'center',
                      justifyContent: 'center',
                      font: 'Manrope',
                      fontSize: 11,
                      fontStyle: 'normal',
                      fontWeight: 800,
                      letterSpacing: '0.88px',
                    }}
                  >
                    {filterAcitveCount}
                  </div>
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                    }}
                    onClick={handleClickFilterClose}
                  >
                    <img src="/images/close_with_bg.svg" className="pr-1" alt="" />
                  </button>
                </>
                : null
            }
          </a>
        </div>
      </div>
      <div className="sr_data_table">
        <DataTable
          columns={columns}
          data={properties}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          fixedHeader={true}
          fixedHeaderScrollHeight={'430px'}
          onRowClicked={handleDetailsPageRedirection}
          onSort={handleSort}
          // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => console.log("currentRowsPerPage, currentPage -- ",currentRowsPerPage, currentPage)}
          sortServer
          onChangeRowsPerPage={handleRowsPerPageChange}
        ></DataTable>
      </div>


      {/* FILTER MODAL */}
      <Modal
        show={showFilterModal}
        onHide={handleCloseFilterModal}
        centered
        size='lg'
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
          <img src="/images/cross_modal.svg" className="cursor-pointor" alt="Close Icon" onClick={handleCloseFilterModal} />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyName">Property Name</label>
                  <input
                    type="text"
                    id="propertyName"
                    name="propertyName"
                    placeholder="Enter Name"
                    className="form-control"
                    value={formik.values.name}
                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyCode">Property Code</label>
                  <input
                    type="text"
                    id="propertyCode"
                    name="propertyCode"
                    placeholder="Enter Property Code"
                    className="form-control"
                    value={formik.values.property_code}
                    onChange={(e) => formik.setFieldValue('property_code', e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="capacityCount">Capacity Count</label>
                                    <input
                                        type="number"
                                        id="capacityCount"
                                        name="capacityCount"
                                        placeholder="Capacity Count"
                                        className="form-control"
                                        value={formik.values.capacity_count}
                                        onChange={(e) => formik.setFieldValue('capacity_count', e.target.value)}
                                    />
                                </div>
                            </div> */}
              {/* <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                                    <label className="form-label" htmlFor="cityName">City Name</label>
                                    <input
                                        type="text"
                                        id="cityName"
                                        name="cityName"
                                        placeholder="Enter City Name"
                                        className="form-control"
                                        value={formik.values.city}
                                        onChange={(e) => formik.setFieldValue('city', e.target.value)}
                                    />
                            </div> */}
              <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                <Form.Label>City</Form.Label>
                <Form.Select
                  placeholder="Select City"
                  id="city_id"
                  isInvalid={formik.touched.city_id && formik.errors.city_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city_id}
                  aria-label="Default select example">
                  <option value="">Select City</option>
                  {
                    cities.map(city => <>
                      <option value={city.value}>{city.label}</option>
                    </>)
                  }
                </Form.Select>
              </div>
              {/* <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="occcupancyCount">Occupancy Count</label>
                                    <input
                                        type="text"
                                        id="occcupancyCount"
                                        name="occcupancyCount"
                                        placeholder="Enter Occupancy Count"
                                        className="form-control"
                                        value={formik.values.occupancy_count}
                                        onChange={(e) => formik.setFieldValue('occupancy_count', e.target.value)}
                                    />
                                </div>
                            </div> */}

              <div className="modal_btn_footer mt-4">
                <button type="button" className="orange_brd_button p-12-16-button mr-3" onClick={() => handleResetFilter()}>RESET</button>
                <button type="submit" className={'basic-button'}>SEARCH</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default withDefaultDashBoardLayout(FoodPropertiesList);
