import { useEffect, useState } from 'react';
import moment from 'moment';
import toaster from '../../custom-hot-toast/CopyToast';

import '../../../css/onboarding-details-tab.css';

function DetailsTab({ residentInfo, onboardingInfo }) {
    const [contractDetails, setContractDetails] = useState(null);
    const [basicDetails, setBasicDetails] = useState(null);

    useEffect(() => {
        if (residentInfo && residentInfo?.contract_details) setContractDetails(residentInfo?.contract_details);
        // if (residentInfo && residentInfo?.basic_details) setBasicDetails(residentInfo?.basic_details);
        if (onboardingInfo) setBasicDetails(onboardingInfo);
    }, [residentInfo, onboardingInfo]); 

    const handleCopy = (text, fieldName) => {
        navigator.clipboard.writeText(text);
        toaster(`${fieldName} is copied`);
    };

    // console.log("residentInfo -- ",residentInfo)
    // console.log("basicDetails -- ",basicDetails)

    return (
        <>
            <div className='row zindex9'>
                <div className='col-md-6'>
                    <div className='brd_box_card pd-24-20'>
                        <div className='guest_part'>
                            <div className='d-flex align-items-center'>
                                <div className='guest_img'><img src='/images/guest.png' className='pr-2' alt='' /></div>
                                <div>
                                    <h1>{basicDetails?.resident_name ?? '--'}</h1>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex align-items-center mr-2'>
                                            <span>Email Id :</span>
                                            <b>{basicDetails?.email ?? '--'}</b>
                                        </div>
                                        <div>
                                            <img src="/images/pipe.svg" alt="Pipe" />
                                        </div>
                                        <div className='d-flex ml-2 align-items-center'>
                                            <span>Phone :</span>
                                            <b
                                                className='cursor-pointor'
                                                onClick={() => handleCopy(basicDetails?.mobile_number, 'Phone number')}
                                            >
                                                {basicDetails?.mobile_number ?? '--'}
                                                <img src="/images/copy.svg" className="pl-1" alt="" />
                                            </b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='info_box'>
                            <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center info_width'>
                                    <div className='info_img'><img src='/images/Gender.svg' className='pr-2' alt='' /></div>
                                    <div>
                                        <h4>Gender</h4>
                                        <span>{basicDetails?.gender ?? '--'}</span>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center info_width'>
                                    <div className='info_img'><img src='/images/Nationality.svg' className='pr-2' alt='' /></div>
                                    <div>
                                        <h4>Nationality</h4>
                                        <span>{basicDetails?.nationality ?? '--'}</span>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center info_width'>
                                    <div className='info_img'><img src='/images/cake.svg' className='pr-2' alt='' /></div>
                                    <div>
                                        <h4>Date of Birth</h4>
                                        <span>{(basicDetails?.dob) ? moment(basicDetails?.dob).format('DD MMM YYYY') : '--'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='brd_box_card'>
                        <div className="box_contain details_tab">
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Student vs Working</h3>
                                <i>:</i>
                                <span>{basicDetails?.resident_type ?? '--'}</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Property</h3>
                                <i>:</i>
                                <span>{basicDetails?.property_details?.property_name ?? '--'}</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>CRM Lead ID</h3>
                                <i>:</i>
                                {
                                    // contractDetails?.lead_generation_id ? (
                                        basicDetails?.id ? (
                                        <span
                                            className='cursor-pointor'
                                            // onClick={() => handleCopy(contractDetails?.lead_generation_id, 'CRM lead id')}
                                            onClick={() => handleCopy(basicDetails?.id, 'CRM lead id')}
                                        >
                                            <img src="/images/copy.svg" className="pr-1" alt="" /> {basicDetails?.id}
                                        </span>
                                    ) : <span>--</span>
                                }
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>LMS Lead ID</h3>
                                <i>:</i>
                                {/* <span
                                    className='cursor-pointor'
                                    onClick={() => handleCopy('2098092', 'LMS lead id')}
                                ><img src="/images/copy.svg" className="pr-1" alt="" /> -- </span> */}
                                <span>--</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>City</h3>
                                <i>:</i>
                                <span>{basicDetails?.city_name?.city_name ?? '--'}</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Cluster</h3>
                                <i>:</i>
                                <span>{basicDetails?.property_details?.cluster_details?.cluster_name ?? '--'}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="brd_box_card mt-3 zindex9">
                <div className="box_head">
                    <div className="d-flex align-items-center">
                        <h2> <img src="/images/file-shield.svg" className="pr-2" alt="" /> VIRTUAL ACCOUNT</h2>
                    </div>
                </div>

                <div className='exit_tab_box'>
                    <div className="box_contain ">
                        <div className="sub_exit_box">
                            <div className="d-flex align-items-center">
                            <div className="sub_exit_data_txt">
                                    <h3>Account Holder Name</h3>
                                    <span>
                                    {basicDetails?.resident_name ?? '--'}
                                    </span>
                                </div>
                                <div className="sub_exit_data_txt">
                                    <h3>Account Number</h3>
                                    <span>
                                    {basicDetails?.mobile_number ? `YOSP${basicDetails?.mobile_number}` : "--" }
                                    </span>
                                </div>
                                <div className="sub_exit_data_txt">
                                    <h3>Bank Name</h3>
                                    <span>
                                    ICICI Bank
                                    </span>
                                </div>
                                <div className="sub_exit_data_txt">
                                    <h3>IFSC</h3>
                                    <span>
                                    ICIC0000104
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brd_box_card mt-3 zindex9">
                <div className="box_head">
                    <div className="d-flex align-items-center">
                        <h2> <img src="/images/file-shield.svg" className="pr-2" alt="" /> POC</h2>
                    </div>
                </div>

                <div className='exit_tab_box'>
                    <div className="box_contain ">
                        <div className="sub_exit_box">
                            <div className="d-flex align-items-center">
                                <div className="sub_exit_data_txt">
                                    <h3>RM</h3>
                                    <span>
                                        {onboardingInfo?.regional_manager_details?.employee_first_name ?? '--'}{' '}
                                        {onboardingInfo?.regional_manager_details?.employee_last_name ?? ''}
                                    </span>
                                </div>
                                <div className="sub_exit_data_txt">
                                    <h3>CM</h3>
                                    <span>
                                        {onboardingInfo?.cluster_manager_details?.employee_first_name ?? '--'}{' '}
                                        {onboardingInfo?.cluster_manager_details?.employee_last_name ?? ''}
                                    </span>
                                </div>
                                <div className="sub_exit_data_txt">
                                    <h3>City Head</h3>
                                    <span>
                                        {onboardingInfo?.city_head_details?.employee_first_name ?? '--'}{' '}
                                        {onboardingInfo?.city_head_details?.employee_last_name ?? ''}
                                    </span>
                                </div>
                                <div className="sub_exit_data_txt">
                                    <h3>PM</h3>
                                    <span>
                                        {onboardingInfo?.property_manager_details?.employee_first_name ?? '--'}{' '}
                                        {onboardingInfo?.property_manager_details?.employee_last_name ?? ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='details_bg'><img src="/images/bg_tab_img.png" alt="" /></div>
        </>
    );

}

export default DetailsTab;