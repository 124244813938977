import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateWebsiteAmanites } from "../../../../helpers/services/websiteServices";
import toast from "react-hot-toast";

const Modalamenity = ({
  amenityConfigData,
  handler,
  openModal,
  amenityIndex,
  actionType,
  deleteModal,
  setDeleteModal,
}) => {
  const [data, setData] = useState({
    name: "",
    discription: "",
    // //slug: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const amenityData = async () => {
    if (actionType == "update") {
      const filterData = amenityConfigData.filter(
        (data, ind) => ind === amenityIndex
      );
      setData(filterData[0]);
    } else {
      setData({
        name: "",
        discription: "",
        //slug: "",
      });
    }
  };
  useEffect(() => {
    if (actionType == "add") {
      setData({
        name: "",
        discription: "",
        //slug: "",
      });
    }
  }, [actionType]);

  const clearData = () => {
    setData({
      name: "",
      discription: "",
      //slug: "",
    });
  };

  useEffect(() => {
    if (actionType == "update") {
      amenityData();
    }
  }, [amenityConfigData, amenityIndex]);

  const handleSubmit = async () => {
    if(data.name.trim() == "" || data.discription.trim() == ""){
      return toast.error("Please fill all details")
    }
    try {
      if (actionType == "update") {
        amenityConfigData[amenityIndex] = data;
      } else if (actionType == "add") {
        amenityConfigData.push(data);
      }
      const bodyData = {
        config_details: amenityConfigData,
      };
      const response = await updateWebsiteAmanites(bodyData);
      if (response.status == "success") {
        toast.success(
          `${
            actionType == "update"
              ? `amenity #${amenityIndex + 1} updated`
              : "New amenity added"
          }`
        );
        handler();
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const filterData = amenityConfigData.filter(
        (data, index) => index != amenityIndex
      );

      const bodyData = {
        config_details: filterData,
      };
      const response = await updateWebsiteAmanites(bodyData);
      if (response.status == "success") {
        toast.success("amenity Deleted");
        setDeleteModal(false);
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => {
          handler(false);
          clearData();
        }}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title className="underline">
            {actionType == "add"
              ? "Add amenity"
              : `amenity #${amenityIndex + 1}`}
          </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={() => handler(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="form_control_box">
            <label htmlFor="amenity-name" className="form-label">
              <b>name</b>
            </label>
            <input
              type="text"
              id="amenity-name"
              className="form-control mt-1"
              name="name"
              onChange={handleChange}
              value={data?.name}
            />
          </div>
          <div className="form_control_box">
            <label htmlFor="amenity-name" className="form-label">
              <b>Description</b>
            </label>
            <textarea
              type="text"
              id="amenity-name"
              className="form-control mt-1"
              name="discription"
              onChange={handleChange}
              value={data?.discription}
            />
          </div>
          {/* <div className="form_control_box">
            <label htmlFor="amenity-name" className="form-label">
              <b>Slug</b>
            </label>
            <input
              type="text"
              id="slug"
              className="form-control mt-1"
              name="slug"
              onChange={handleChange}
              value={data?.slug}
            />
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className={"basic-button"}
            onClick={handleSubmit}
          >
            {actionType == "add" ? "ADD NEW amenity" : "UPDATE"}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header>
          <b>Delete Amenity #{amenityIndex + 1}</b>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              setDeleteModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>Are you sure want to delete the amenity ?</Modal.Body>
        <Modal.Footer>
          <button className="basic-button" onClick={handleDelete}>
            Delete amenity
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Modalamenity;
