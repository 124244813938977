import { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { fetchResidentOnboardingDetails, fetchResidentWalletLedger, sendEmailToResident } from '../../../helpers/services/api.services';
import toast from 'react-hot-toast';
import '../../../css/resident-details-tab.css';

function CollectionTab({ residentId }) {
    const [walletLedger, setWalletLedger] = useState([]);
    const [collectionLedger, setCollectionLedger] = useState([]);
    const [balanceData, setBalanceData] = useState({});
    const [loading, setLoading] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [popupType, setPopupType] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        setLoading(true);
        loadResidentOnboardingDetails();
    }, [residentId]);

    const loadResidentOnboardingDetails = async () => {
        let response = await fetchResidentOnboardingDetails(residentId);

        if (response && response.status === "success") {
            setCollectionLedger(response?.data?.collection_schedule)
            setBalanceData(response?.data?.balance);
        }
        setLoading(false);
    }

    const formatServicePeriod = (startDate, endDate) => {
        let start = moment(startDate) || null;
        let end = moment(endDate) || null;
        if (!start || !end) return '';
        let startMonth = start.format('MMM');
        let endMonth = end.format('MMM');
        let startYear = start.format('YY');
        let endYear = end.format('YY');

        if (startMonth === endMonth && startYear === endYear) {
            return `${startMonth}'${startYear}`;
        }
        else if (startYear === endYear) {
            return `${startMonth}-${endMonth}'${startYear}`;
        }
        else {
            return `${startMonth}'${startYear}-${endMonth}'${endYear}`;
        }
    }

    const handlePopup = (data, type) => {
        setPopupData(data);
        setShowPopup(true);
        setPopupType(type);
    }

    return (
        <>
            <div>
                <div className='table_main_tit d-flex justify-content-between align-items-center pb-20'>
                    <h2>Collection Schedule</h2>
                </div>
                <div className='ledger_table commen_table mob_ledger_table'>
                    <div className='page-table-box'>
                        <table>
                            <thead>
                                <tr>
                                    <th>Schedule Type</th>
                                    <th>Amount</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loading ?
                                        <tr>
                                            <td colSpan={3}>Ledger loading...</td>
                                        </tr> :
                                        <>
                                            {
                                                (collectionLedger && collectionLedger.length) ? collectionLedger.map((ledger, index) => (
                                                    <tr key={index}
                                                    >
                                                        {/* <td>{moment(new Date(ledger?.payment_datetime_string)).format('MMM DD')}</td> */}
                                                        <td>{`${ledger?.payment_slug?.replace(/_/g, ' ')?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}-${formatServicePeriod(ledger?.service_period_start_date, ledger?.service_period_end_date)}`}</td>
                                                        <td style={{color : ledger?.payment_status === 'pending' ? 'red' : ledger?.payment_status === 'success' ? "green" : "black"}}> <>₹{ledger?.gross_amount}</> </td>
                                                        <td className='text-right p-2'>
                                                            <div  className='orange_brd_butto' onClick={() => { handlePopup(ledger, 'schedule') }} style={{color : "orange" }}> <b>View More</b> </div>
                                                        </td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td colSpan={3}>No ledger found</td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ width : "92%" , position : "absolute" , bottom : "120px" }}>
                    <button className='orange_brd_button'
                    style={{ padding: '5px 10px', fontSize: '14px'}}
                    onClick={() => { handlePopup(balanceData, 'balance') }}>View Balance Details</button>
                </div>
            </div>
            {
                showPopup && popupData &&

                <>
                    {
                        popupType === 'schedule' ?
                            <div className='mob_col_sch_popup'
                                onClick={() => { setShowPopup(false) }}
                            >
                                <div className='popup_inner'
                                    onClick={(e) => { e.stopPropagation() }}
                                >
                                    <div className='popup_header'>
                                        <h2>{`${popupData?.payment_slug?.replace(/_/g, ' ')?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}-${formatServicePeriod(popupData?.service_period_start_date, popupData?.service_period_end_date)}`}</h2>
                                        <div className='' onClick={() => { setShowPopup(false) }}><img src="/images/cross_modal.svg" alt="Close Icon" /></div>
                                    </div>
                                    <div className='popup_body'>
                                        <div className='popup_body_inner'>
                                            <div className='popup_body_left'>
                                                <h3>Service Period</h3>
                                                <p>{`${moment(popupData?.service_period_start_date).format("DD-MMM-YYYY")} to ${moment(popupData?.service_period_end_date).format("DD-MMM-YYYY")}`}</p>
                                                <h3>Payment Status</h3>
                                                <p>{popupData?.payment_status?.split(' ')?.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</p>
                                            </div>
                                            <div className='popup_body_right'>
                                                <h3>Amount</h3>
                                                <p>₹{popupData?.gross_amount}</p>
                                                <h3>Late Fee</h3>
                                                <p>₹{popupData?.late_fee_amount}</p>
                                                <h3>Due Date</h3>
                                                <p>{moment(popupData?.due_date).format("DD-MMM-YYYY")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='mob_col_sch_popup'
                                onClick={() => { setShowPopup(false) }}
                            >
                                <div className='popup_inner'
                                    onClick={(e) => { e.stopPropagation() }}
                                >
                                    <div className='popup_header'>
                                        <h2>Balance - {`FY ${moment().format("YY")}-${moment().add(1, "year").format("YY")}`}</h2>
                                        <div className='' onClick={() => { setShowPopup(false) }}><img src="/images/cross_modal.svg" alt="Close Icon" /></div>
                                    </div>

                                    <div className='mob-balance-summary'>
                                        <div className='mob-balance-details'>
                                            <div className='mob-balance-detail-box'>
                                                <p>
                                                    <span className='mob-balance-detail-header'>Wallet Balance</span>
                                                    <span className='mob-balance-detail-data'>₹{balanceData?.wallet}</span>
                                                </p>
                                            </div>
                                            <div className='mob-balance-detail-box'>
                                                <p>
                                                    <span className='mob-balance-detail-header'>Lien Amount</span>
                                                    <span className='mob-balance-detail-data'>₹{balanceData?.lien_amount}</span>
                                                </p>
                                            </div>
                                            <div className='mob-balance-detail-box'>
                                                <p>
                                                    <span className='mob-balance-detail-header'>SD Balance</span>
                                                    <span className='mob-balance-detail-data'>₹{balanceData?.sd_amount}</span>
                                                </p>
                                            </div>
                                            <div className='mob-balance-detail-box'>
                                                <p>
                                                    <span className='mob-balance-detail-header'>Total Outstanding</span>
                                                    <span className='mob-balance-detail-data'>₹{balanceData?.os}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </>
            }
        </>
    );

}

export default CollectionTab;