import { BASE_API_URL } from "../constants";
import apiClient from "./apiClient";
import API_ACTION from "../actions/api.action";



const BASE_CRM_DASHBOARD_URL = BASE_API_URL + "/crm_dashboard/v1/admin/";

export const getWebsiteAmenities = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getwebsiteAmenities)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getWebsiteWhyYourSpace = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getWebsiteWhyYourSpace)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getWebsiteTestimonials = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getWebsiteTestimonials)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getWebsiteFaqs = async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getWebsiteFaqs)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getWebsiteCarousal= async () => {
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + "get_website_homepage/" + API_ACTION.getWebsiteCarousal)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateWebsiteFaqs = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getWebsiteFaqs, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const updateWebsiteCarousel = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getWebsiteCarousal, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateWebsiteTestimonials = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getWebsiteTestimonials, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateWebsiteAmanites = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getwebsiteAmenities, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const updateWebsiteWhySpace = async (data) => {
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + "update_website_homepage/" + API_ACTION.getWebsiteWhyYourSpace, data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const getCityList = async()=>{
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const getCityDetails = async(id)=>{
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity + `/${id}` )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateCityDetails = async(id , data)=>{
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity + `/${id}` , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const createCityFaq = async(data)=>{
    try {
        return await apiClient.post(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity + "/create_faqs" , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const updateCityFaq = async( data)=>{
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity + "/update_faqs" , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const deleteCityFaq = async(data)=>{
    try {
        return await apiClient.delete(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity + "/destroy_faqs" , data  )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updatePropertyDetails = async( cityId , propertyId , data)=>{
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity +  `/${cityId}/` + API_ACTION.getWebsiteProperty + `/${propertyId}` , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const createPropertyFaq = async( id , data)=>{
    try {
        return await apiClient.post(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity + `/${id}/` + API_ACTION.getWebsiteProperty +  "/create_faqs" , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updatePropertyFaq = async( id , data)=>{
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity + `/${id}/` + API_ACTION.getWebsiteProperty +  "/update_faqs" , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const deletePropertyFaq = async( id , data)=>{
    try {
        return await apiClient.delete(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity + `/${id}/` + API_ACTION.getWebsiteProperty + "/destroy_faqs" , data  )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const getWebsitePropertyList = async( id)=>{
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity +  `/${id}/` + API_ACTION.getWebsiteProperty )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const getWebsitePropertyDetails = async( cityId , propertyId)=>{
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteCity +  `/${cityId}/` + API_ACTION.getWebsiteProperty + `/${propertyId}` )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const getInstitutionList = async()=>{
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteInstitution )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const getInstitutionDetails = async(id)=>{
    try {
        return await apiClient.get(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteInstitution + `/${id}` )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const updateInstitutionDetails = async(id , data)=>{
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteInstitution + `/${id}` , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const createInstituteFaq = async(data)=>{
    try {
        return await apiClient.post(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteInstitution + "/create_faqs" , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}
export const updateInstituteFaq = async( data)=>{
    try {
        return await apiClient.put(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteInstitution + "/update_faqs" , data )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const deleteInstituteFaq = async(data)=>{
    try {
        return await apiClient.delete(BASE_CRM_DASHBOARD_URL + API_ACTION.getWebsiteInstitution + "/destroy_faqs" , data  )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export const uploadImage = async(data)=>{
    try {
        return await apiClient.post(BASE_API_URL + "/crm_dashboard/v1/document/media_file_upload",data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }

}

export const updateImage = async(data)=>{
    try {
        return await apiClient.post(BASE_API_URL + "/crm_dashboard/v1/document/media_file_update",data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }

}
