import { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import { fetchProperties } from "../../helpers/services/api.services";
import { fetchClusters, fetchCities, fetchStates, fetchRoomTypes, fetchEmployees, fetchCommonAssets, fetchRoomAssets } from "../../helpers/services/admin.services";
import PropertyConstant from "../../helpers/constants/properties";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import DetailsTab from "../../components/tabs/create-property/details.tab";
import CreateBookingPlan from "../../components/tabs/create-property/booking.plan";
import OwnerKyc from "../../components/tabs/create-property/owner.kyc";
import CreateOperations from "../../components/tabs/create-property/operations";
import GlobalStateContext from "../../GlobalStateContext";
import { useFormik } from 'formik';
import { Form } from "react-bootstrap";
import { DownloadModal } from "./downloadModal/DownloadModal";

function PropertiesList() {
  const navigate = useNavigate();
  const { roles } = useContext(GlobalStateContext);
  const {propertyType} = useParams()

  const [propertyStatus, setPropertyStatus] = useState([]);
  const [activePropertyStatus, setActivePropertyStatus] = useState("All");
  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [downloadPropertyId, setdownloadPropertyId] = useState(null);

  const [clusters, setClusters] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);

  const [emplyees, setEmployees] = useState([]);
  const [commonAssets, setCommonAssets] = useState([]);
  const [roomAssets, setRoomAssets] = useState([]);

  // Data-table
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(100);
  const [page, setPage] = useState(1);

  const [serachObject, setSerachObject] = useState({})
  const [sortObject, setSortObject] = useState({})
  const [filterAcitveCount, setFilterAcitveCount] = useState(0)
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleCloseFilterModal = () => setShowFilterModal(false);

  const handleShowFilterModal = () => {
    if (Object.keys(serachObject).length) {
      Object.keys(serachObject).map((key) => {
        formik.setFieldValue(key, serachObject[key])
      })
    }
    setShowFilterModal(true);
  }

  //Download modal
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const openDownloadModal = () => setShowDownloadModal(true)
  const closeDownloadModal = () => setShowDownloadModal(false)

  // Create Property Modal
  const handleCloseConfirmNocModal = () => setShowCreatePropertyModal(false);
  const handleCreatePropertyModal = () => setShowCreatePropertyModal(true);

  useEffect(() => {
    setPropertyStatus(PropertyConstant.propertyStatus);
    loadProperty(page, serachObject, sortObject, rowsPerPage);
    loadCities();
  }, [propertyType]);

  const loadProperty = async (currentPage, searchPayload, sortPayload, current_rowPerPage) => {
    setLoading(true);

    let response = await fetchProperties(currentPage, searchPayload, sortPayload, current_rowPerPage , propertyType);

    if (response && response?.status === "success" && response.data && response.data.length > 0) {
      setProperties(response.data);

      if (response?.meta && response?.meta?.total_data_count && response?.meta?.total_pages) {
        setTotalRows(response?.meta?.total_data_count);
      }
      setPage(currentPage);
      handleCloseFilterModal();
      setLoading(false);
    } else {
      setProperties([]);
      handleCloseFilterModal();
      setLoading(false);
    }
  };

  const filteredResidentsData = async (status) => {
    setActivePropertyStatus(status);
  };

  const handlePageChange = (page) => {
    loadProperty(page, serachObject, sortObject, rowsPerPage);
  };

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.property_name,
      width: "20%",
      sortable: true,
      sortField: 'property_name'
    },
    {
      name: 'Property Code',
      // selector: (row) => row.id,
      selector: (row) => row.property_code,
      sortable: true,
      sortField: "property_code"
    },
    // {
    //   name: 'Capacity',
    //   selector: (row) => row.capacity_count,
    //   sortable: true,
    //   // sortField:"capacity_count"
    // },
    {
      name: 'City',
      selector: (row) => row.city,
      // sortable: true,
      // sortField:"city"
    },
    {
      name: 'State',
      selector: (row) => row?.state_details?.name,
      // sortable: true,
      // sortField:"city"
    },
    {
      name: 'Cluster',
      selector: (row) => row?.cluster_details?.cluster_name,
      // sortable: true,
      // sortField:"city"
    },

    // {
    //   name: 'Occupancy',
    //   selector: (row) => row?.occupancy_count,
    //   sortable: true,
    //   // sortField:"occupancy_count"
    // },
    // {
    //   name: 'Sales',
    //   selector: (row) => '1,34,000',
    //   sortable: false,
    // },
    // {
    //   name: 'Created by',
    //   selector: (row) => 'Brooklyn Simmons',
    //   sortable: false,
    // },
    {
      name: 'Status',
      selector: (row) => row?.status,
      cell: row => (
        <span className="success_status_badge">{row?.status}</span>
      ),
      // sortable: false,
    },
    {
      name: '',
      width: "5%",
      cell: row => (
        <>
          {!roles?.marketing_manager && <>
            <Link to={`/food_verification/${row?.id}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                fill="#000000"
                height="20px"
                width="20px"
                version="1.1"
                id="Layer_1"
                viewBox="0 0 470 470"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <g>
                      <path d="M307.5,117.5C234.439,117.5,175,176.939,175,250s59.439,132.5,132.5,132.5S440,323.061,440,250 S380.561,117.5,307.5,117.5z M307.5,367.5C242.71,367.5,190,314.79,190,250s52.71-117.5,117.5-117.5S425,185.21,425,250 S372.29,367.5,307.5,367.5z" />
                      <path d="M367.5,250c0-33.084-26.916-60-60-60s-60,26.916-60,60s26.916,60,60,60S367.5,283.084,367.5,250z M262.5,250 c0-24.813,20.187-45,45-45s45,20.187,45,45s-20.187,45-45,45S262.5,274.813,262.5,250z" />
                      <path d="M432.5,87.5H163.126V65c0-4.142-3.358-7.5-7.5-7.5H67.5c-4.142,0-7.5,3.358-7.5,7.5v22.5H37.5 C16.822,87.5,0,104.322,0,125v250c0,20.678,16.822,37.5,37.5,37.5h395c20.678,0,37.5-16.822,37.5-37.5V125 C470,104.322,453.178,87.5,432.5,87.5z M75,72.5h73.126v15H75V72.5z M455,375c0,12.407-10.093,22.5-22.5,22.5h-395 C25.093,397.5,15,387.407,15,375V125c0-12.407,10.093-22.5,22.5-22.5h395c12.407,0,22.5,10.093,22.5,22.5V375z" />
                      <path d="M307.5,147.5c-21.735,0-42.519,6.75-60.104,19.521c-3.352,2.434-4.095,7.124-1.661,10.476 c2.433,3.351,7.123,4.096,10.476,1.661c15.006-10.898,32.742-16.658,51.29-16.658c48.248,0,87.5,39.252,87.5,87.5 c0,48.248-39.252,87.5-87.5,87.5C259.253,337.5,220,298.248,220,250c0-18.547,5.76-36.283,16.659-51.29 c2.434-3.352,1.69-8.042-1.662-10.476c-3.353-2.434-8.043-1.69-10.476,1.662C211.75,207.481,205,228.265,205,250 c0,56.519,45.981,102.5,102.5,102.5S410,306.519,410,250C410,193.481,364.019,147.5,307.5,147.5z" />
                      <path d="M137.5,117.5h-80C42.336,117.5,30,129.836,30,145s12.336,27.5,27.5,27.5h80c15.164,0,27.5-12.336,27.5-27.5 S152.664,117.5,137.5,117.5z M137.5,157.5h-80c-6.893,0-12.5-5.607-12.5-12.5s5.607-12.5,12.5-12.5h80 c6.893,0,12.5,5.607,12.5,12.5S144.393,157.5,137.5,157.5z" />
                    </g>
                  </g>
                </g>
              </svg>

            </Link>
          </>}
        </>
      )
    },
    {
      name: '',
      width: "5%",
      cell: row => (
        <>
          {!roles?.marketing_manager && <>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => handleButtonClick(row?.id)}
            >
              <path
                d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
                stroke="#566366"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.83301 8.33301L9.99967 12.4997L14.1663 8.33301"
                stroke="#566366"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 12.5V2.5"
                stroke="#566366"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </>}
        </>
      )
    },
    {
      name: '',
      selector: (row) => row?.id,
      width: "3%",
      cell: row => (
        <Link to={`/property-details/${row?.id}`}>
          <img src="/images/right_arrow.svg" alt="Details Icon" />
        </Link>
      )
    },
  ];

  const handleDetailsPageRedirection = (data) => {
    navigate(`/property-details/${data?.id}`);
  }

  const handleButtonClick = (id) => {
    openDownloadModal()
    setdownloadPropertyId(id)
  }

  const handleOpenCreatePropertyModal = async () => {
    await loadClusters();
    await loadStates();
    await loadCities();
    await loadRoomTypes();
    await loadEmployees();
    await loadCommonAssets();
    await loadRoomAssets();
    await handleCreatePropertyModal();
  }

  const loadClusters = async () => {
    let response = await fetchClusters();

    if (response && response.data && response.data.length > 0) {
      let clusters = response.data.map(element => {
        return { value: element?.id, label: element?.cluster_name }
      });

      setClusters(clusters);
    }
  }

  const loadStates = async () => {
    let response = await fetchStates();

    if (response && response.data && response.data.length > 0) {
      let states = response.data.map(element => {
        return { value: element?.id, label: element?.name }
      });
      setStates(states);
    }
  }

  const loadCities = async () => {
    let response = await fetchCities();

    if (response && response.data && response.data.length > 0) {
      let cities = response.data.map(element => {
        return { value: element?.id, label: element?.city_name }
      });
      setCities(cities);
    }
  }

  const loadRoomTypes = async () => {
    let response = await fetchRoomTypes();

    if (response && response.data && response.data.length > 0) {
      let roomTypes = response.data.map(element => {
        return { value: element?.id, label: element?.name, bedsPerRoom: element?.beds_per_room }
      });

      setRoomTypes(roomTypes);
    }
  }

  const loadEmployees = async () => {
    let response = await fetchEmployees();
    if (response && response.data && response.data.length > 0) {
      let states = response.data.map(element => {
        return { value: element?.id, label: element?.name }
      });
      setEmployees(response.data);
    }
  }

  const loadCommonAssets = async () => {
    let response = await fetchCommonAssets();
    if (response && response.data && response.data.length > 0) {
      setCommonAssets(response.data);
    }
  }

  const loadRoomAssets = async () => {
    let response = await fetchRoomAssets();
    if (response && response.data && response.data.length > 0) {
      setRoomAssets(response.data);
    }
  }


  const handleSort = async (column, sortDirection) => {
    let sort_obj = {
      sort_by: column.sortField,
      sort_direction: sortDirection
    }

    if (column.sortField) {
      setSortObject(sort_obj)
      loadProperty(page, serachObject, sort_obj, rowsPerPage);
    }
  };


  const initialValues = {
    name: "",
    property_code: "",
    // capacity_count:"",
    city: "",
    city_id: "",
    // occupancy_count:"",
  }

  const validationSchema = null

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {

      let search_obj = JSON.parse(JSON.stringify(values))
      const has_value = Object.values(values).some(val => val ? true : false)
      Object.keys(values).map((key) => {
        if (!values[key]) {
          delete search_obj[key]
        }
      })
      if (has_value) {
        setSerachObject(search_obj);
        setFilterAcitveCount(Object.keys(search_obj).length)
        loadProperty(1, search_obj, sortObject, rowsPerPage);
      }
    },
  });

  const handleClickFilterClose = (e) => {
    e.stopPropagation()
    setSerachObject({})
    setFilterAcitveCount(0)
    formik.resetForm();
    loadProperty(1, {}, sortObject, rowsPerPage, rowsPerPage);
  }

  const handleRowsPerPageChange = (currentRowsPerPage, currentPage) => {
    setPage(currentPage)
    setRowsPerPage(currentRowsPerPage)
    loadProperty(currentPage, {}, sortObject, currentRowsPerPage);
  }

  const handleResetFilter = (e) => {
    formik.resetForm();
  }


  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Properties</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Properties</a> <span>/</span>
              </li>
              {/* <li>YS BLR - YS Banergatta</li>  */}
            </ul>
          </div>
        </div>
        {
          roles?.super_admin || roles?.tech_admin ?
            <div className="create-prop">
              <button className="basic-button" onClick={handleOpenCreatePropertyModal}>
                <img
                  src="/images/plus-circle.svg"
                  className="pr-2"
                  alt=""
                />
                Add Property
              </button>
            </div>
            :
            null
        }
      </div>

      <div className="d-flex justify-content-between align-items-center pb-32">
        <div className="status-box">
          {roles?.super_admin || roles?.tech_admin ?
            propertyStatus.map((status, index) => (
              <a
                key={index}
                href="#"
                className={status?.label === activePropertyStatus ? "active" : ""}
                // onClick={() => filteredResidentsData(status?.label)}
                onClick={() => {
                  let search_obj = { ...serachObject, status: status.key }
                  setSerachObject(search_obj)
                  filteredResidentsData(status?.label);
                  loadProperty(1, search_obj, sortObject, rowsPerPage);
                }}
              >
                {status?.label}
              </a>
            ))
            : null
          }
        </div>
        <div className="status-box">
          <a
            onClick={handleShowFilterModal}
            className="cursor-pointor"
            style={{
              display: "flex",
              direction: 'row'
            }}
          >
            <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
            {
              filterAcitveCount > 0 ?
                <>
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      backgroundColor: '#FF5700',
                      borderRadius: "50%",
                      marginLeft: 4,
                      color: '#FFF4ED',
                      textAlign: 'center',
                      justifyContent: 'center',
                      font: 'Manrope',
                      fontSize: 11,
                      fontStyle: 'normal',
                      fontWeight: 800,
                      letterSpacing: '0.88px',
                    }}
                  >
                    {filterAcitveCount}
                  </div>
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                    }}
                    onClick={handleClickFilterClose}
                  >
                    <img src="/images/close_with_bg.svg" className="pr-1" alt="" />
                  </button>
                </>
                : null
            }
          </a>
        </div>
      </div>
      <div className="sr_data_table">
        <DataTable
          columns={columns}
          data={properties}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          fixedHeader={true}
          fixedHeaderScrollHeight={'430px'}
          onRowClicked={handleDetailsPageRedirection}
          onSort={handleSort}
          // onChangeRowsPerPage={(currentRowsPerPage, currentPage) => console.log("currentRowsPerPage, currentPage -- ",currentRowsPerPage, currentPage)}
          sortServer
          onChangeRowsPerPage={handleRowsPerPageChange}
        ></DataTable>
      </div>

      <Modal
        show={showCreatePropertyModal}
        onHide={handleCloseConfirmNocModal}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal create-prop_modal">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2 className="title">Create Property</h2>
              </div>
              <div style={{ cursor: "pointer" }}>
                <span onClick={handleCloseConfirmNocModal} className="">
                  <img src="/images/cross_modal.svg" alt="Close Icon" />
                </span>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
            {/* Details Tab View */}
            <div className="main_tab">
              <Tabs
                defaultActiveKey="detailsTab"
                id="resident-details-view"
                className="mb-4"
              >
                <Tab eventKey="detailsTab" title="Details">
                  <DetailsTab
                    onSaveButtonClick={handleCreatePropertyModal}
                    handleCloseModal={handleCloseConfirmNocModal}
                    refreshData={() => loadProperty(1, serachObject, sortObject, rowsPerPage)}
                    clusters={clusters}
                    states={states}
                    cities={cities}
                    setupPropertyData={(property_id) => setPropertyId(property_id)}
                  />
                </Tab>
                <Tab eventKey="bookingPlan" title="Booking Plans">
                  <CreateBookingPlan
                    roomTypes={roomTypes}
                    savedPropertyId={propertyId}
                    handleCloseModal={handleCloseConfirmNocModal}
                  />
                </Tab>
                <Tab eventKey="owner-kyc" title="Owner & KYC">
                  <OwnerKyc savedPropertyId={propertyId} />
                </Tab>
                {/* <Tab eventKey="website-list" title="Website Listing">
                  <WebsiteListing />
                </Tab> */}
                <Tab eventKey="exit" title="Operations">
                  <CreateOperations
                    savedPropertyId={propertyId}
                    emplyees={emplyees}
                    commonAssets={commonAssets}
                    roomAssets={roomAssets}
                    handleCloseModal={handleCloseConfirmNocModal}
                    refreshData={() => loadProperty(1, serachObject, sortObject, rowsPerPage)}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* FILTER MODAL */}
      <Modal
        show={showFilterModal}
        onHide={handleCloseFilterModal}
        centered
        size='lg'
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
          <img src="/images/cross_modal.svg" className="cursor-pointor" alt="Close Icon" onClick={handleCloseFilterModal} />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyName">Property Name</label>
                  <input
                    type="text"
                    id="propertyName"
                    name="propertyName"
                    placeholder="Enter Name"
                    className="form-control"
                    value={formik.values.name}
                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="propertyCode">Property Code</label>
                  <input
                    type="text"
                    id="propertyCode"
                    name="propertyCode"
                    placeholder="Enter Property Code"
                    className="form-control"
                    value={formik.values.property_code}
                    onChange={(e) => formik.setFieldValue('property_code', e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="capacityCount">Capacity Count</label>
                                    <input
                                        type="number"
                                        id="capacityCount"
                                        name="capacityCount"
                                        placeholder="Capacity Count"
                                        className="form-control"
                                        value={formik.values.capacity_count}
                                        onChange={(e) => formik.setFieldValue('capacity_count', e.target.value)}
                                    />
                                </div>
                            </div> */}
              {/* <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                                    <label className="form-label" htmlFor="cityName">City Name</label>
                                    <input
                                        type="text"
                                        id="cityName"
                                        name="cityName"
                                        placeholder="Enter City Name"
                                        className="form-control"
                                        value={formik.values.city}
                                        onChange={(e) => formik.setFieldValue('city', e.target.value)}
                                    />
                            </div> */}
              <div className="custom-single-select col-md-6 col-lg-4 col-sm-6">
                <Form.Label>City</Form.Label>
                <Form.Select
                  placeholder="Select City"
                  id="city_id"
                  isInvalid={formik.touched.city_id && formik.errors.city_id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city_id}
                  aria-label="Default select example">
                  <option value="">Select City</option>
                  {
                    cities.map(city => <>
                      <option value={city.value}>{city.label}</option>
                    </>)
                  }
                </Form.Select>
              </div>
              {/* <div className="col-md-6 col-lg-4 col-sm-6">
                                <div className="form_control_box">
                                    <label className="form-label" htmlFor="occcupancyCount">Occupancy Count</label>
                                    <input
                                        type="text"
                                        id="occcupancyCount"
                                        name="occcupancyCount"
                                        placeholder="Enter Occupancy Count"
                                        className="form-control"
                                        value={formik.values.occupancy_count}
                                        onChange={(e) => formik.setFieldValue('occupancy_count', e.target.value)}
                                    />
                                </div>
                            </div> */}

              <div className="modal_btn_footer mt-4">
                <button type="button" className="orange_brd_button p-12-16-button mr-3" onClick={() => handleResetFilter()}>RESET</button>
                <button type="submit" className={'basic-button'}>SEARCH</button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <DownloadModal showModal={showDownloadModal} setShowModal={setShowDownloadModal} propertyId={downloadPropertyId} />
    </>
  );
}

export default withDefaultDashBoardLayout(PropertiesList);
