import React, { useEffect, useState } from "react";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import { Link, useNavigate } from "react-router-dom";
import { getCityList, getInstitutionList } from "../../../helpers/services/websiteServices";
import DataTable from "react-data-table-component";

const Institution = () => {
  const [cityData, setCityData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const fetchData = async () => {
    setLoading(true);
    const response = await getInstitutionList();
    if (response && response.data && response.data.length > 0) {
      setCityData(response.data);
    } else {
      setCityData([]);
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "10%",
      sortable: true,
      sortField: "id",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "40%",
      sortable: true,
      sortField: "name",
    },
    {
      name: "Slug",
      selector: (row) => row.slug,
      width: "40%",
      sortable: true,
      sortField: "slug",
    },
    {
      name: "",
      selector: (row) => row?.id,
      width: "10%",
      cell: (row) => (
        <Link to={`/website/institution/${row?.id}`}>
          <img src="/images/right_arrow.svg" alt="Details Icon" />
        </Link>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Website</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Website</a> <span>/</span>
              </li>
              <li>Institution</li>
            </ul>
          </div>
        </div>
      </div>
      <div className=" sr_data_table">
        <DataTable
          columns={columns}
          data={cityData}
          progressPending={loading}
          fixedHeader={true}
          fixedHeaderScrollHeight={"430px"}
          pagination
          onRowClicked={(data)=> navigate(`/website/institution/${data?.id}`)}
        />
      </div>
    </>
  );
};

export default withDefaultDashBoardLayout(Institution);
