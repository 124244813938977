import React, { useEffect, useState } from "react";
import withDefaultDashBoardLayout from "../../../../components/layouts/withDefaultDashBoardLayout";
import { Link, useParams } from "react-router-dom";
import {
  deleteCityFaq,
  getCityDetails,
  getWebsitePropertyList,
} from "../../../../helpers/services/websiteServices";
import UpdateCity from "../modals/UpdateCity";
import UploadImage from "../modals/UploadCityImage";
import { Accordion, Modal } from "react-bootstrap";
import FaqModal from "../modals/FaqModal";
import DeleteImageModal from "../../DeleteImageModal";
import toast from "react-hot-toast";

const CityDetails = () => {
  const { id } = useParams();
  const [cityData, setCityData] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [openFaqsModal, setopenFaqsModal] = useState(false);
  const [faqSelectedData, setFaqsSelectedData] = useState(null);
  const [imageUploadData, setImageUploadData] = useState(null);
  const [faqActionType, setFaqActionType] = useState(null);
  const [propertyList, setPropertyList] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [deletefaqModal, setDeleteFaqModal] = useState(false);
  const [deletefaqModalData, setDeleteFaqModalData] = useState(null);
  const [loadingDeleteFaq, setLoadingDeleteFaq] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const response = await getCityDetails(id);
    if (response && !response.data.errors) {
      setCityData(response.data);
      setLoading(false);
    } else {
      setCityData(null);
      setLoading(false);
    }
  };
  const fetchPropertyData = async () => {
    const response = await getWebsitePropertyList(id);
    if (response && !response.data.errors) {
      setPropertyList(response.data);
    } else {
      setPropertyList(null);
    }
  };

  const deleteFaqData = async () => {
    setLoadingDeleteFaq(true);
    const response = await deleteCityFaq(deletefaqModalData);
    if (response && !response.data.errors && response.status == "success") {
      setCityData(response.data);
      toast.success("Faq deleted");
      setDeleteFaqModal(false);
      setDeleteFaqModalData(null);
      setLoadingDeleteFaq(false);
    } else {
      toast.error(response?.data?.errors);
      setLoadingDeleteFaq(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPropertyData();
  }, []);

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Website</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Website</a> <span>/</span>
              </li>
              <li>
                <Link to={"/website/city"}>
                  City <span> /</span>
                </Link>
                {cityData?.city_data?.city_title}
              </li>
            </ul>
          </div>
        </div>
        {cityData && (
          <div>
            <button
              className=" basic-button"
              onClick={() => setOpenUpdateModal(true)}
            >
              {" "}
              city details{" "}
            </button>
          </div>
        )}
      </div>

      {loading ? (
        <div className=" text-center">Loading...</div>
      ) : (
        <>
          {cityData ? (
            <>
              <div className="row">
                <div className="col-md-6 col-lg-8 ">
                  <div className="row mb-5 mr-2">
                    <label htmlFor="" className=" mt-2 mb-2">
                      {" "}
                      <b>City Property Images</b>{" "}
                    </label>
                    <div
                      className="d-flex scroll-bar-hide"
                      style={{
                        overflowX: "scroll",
                      }}
                    >
                      <div
                        className="col-lg-3 bg-light fs-1 mr-2 rounded"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "154px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setImageUploadData({
                            media_type: "city_property_image",
                            media_display_order:
                              cityData?.city_property_image[
                                cityData?.city_property_image.length - 1
                              ]?.media_display_order,
                            media_reference_id: id,
                            media_reference_type: "City",
                          });
                          setUploadImageModal(!uploadImageModal);
                        }}
                      >
                        +
                      </div>
                      {cityData?.city_property_image?.map((images, index) => (
                        <div
                          className=" col-lg-3 ml-3 position-relative"
                          key={index}
                        >
                          <img
                            src={images?.media_url}
                            alt=""
                            className=" w-100 rounded"
                            style={{ height: "154px" }}
                          />
                          <div
                            className="position-absolute "
                            style={{ top: 1, right: 15 }}
                          >
                            <img
                              src="/images/trash-03.png"
                              alt=""
                              style={{ height: "3vh" }}
                              role="button"
                              onClick={() => {
                                setDeleteData({
                                  id: images?.id,
                                  is_active: false,
                                });
                                setDeleteModal(true);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row my-5 mr-2">
                    <label htmlFor="" className=" mt-2 mb-2">
                      {" "}
                      <b>City Description Images</b>{" "}
                    </label>
                    <div
                      className="d-flex scroll-bar-hide"
                      style={{
                        overflowX: "scroll",
                      }}
                    >
                      <div
                        className="col-lg-3 bg-light fs-1 rounded"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "154px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setImageUploadData({
                            media_type: "city_desc_image",
                            media_display_order:
                              cityData?.city_images[
                                cityData?.city_images.length - 1
                              ]?.media_display_order,
                            media_reference_id: id,
                            media_reference_type: "City",
                          });
                          setUploadImageModal(!uploadImageModal);
                        }}
                      >
                        +
                      </div>
                      {cityData?.city_images?.map((images, index) => (
                        <div
                          className=" col-lg-4 position-relative ml-3"
                          key={index}
                        >
                          <img
                            src={images?.media_url}
                            alt=""
                            className=" w-100 rounded"
                            style={{ height: "154px" }}
                          />
                          <div
                            className="position-absolute "
                            style={{ top: 1, right: 15 }}
                          >
                            <img
                              src="/images/trash-03.png"
                              style={{ height: "3vh" }}
                              alt=""
                              role="button"
                              onClick={() => {
                                setDeleteData({
                                  id: images?.id,
                                  is_active: false,
                                });
                                setDeleteModal(true);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className=" col-md-6 col-lg-4">
                      <label htmlFor="" className=" mb-2">
                        {" "}
                        <b>City Logo</b>{" "}
                      </label>
                      {cityData?.city_image?.media_url ? (
                        <>
                          <div className=" position-relative border">
                            <img
                              src={cityData?.city_image?.media_url}
                              alt="City Logo"
                              className=""
                              style={{ height: "35vh" }}
                            />
                            <div
                              className="position-absolute"
                              style={{ top: 1, right: 5 }}
                            >
                              <img
                                src="/images/edit.svg"
                                alt=""
                                role="button"
                                onClick={() => {
                                  setImageUploadData({
                                    media_type: "city_image",
                                    media_display_order:
                                      cityData?.city_image?.media_display_order,
                                    media_reference_id: id,
                                    media_reference_type: "City",
                                  });
                                  setUploadImageModal(!uploadImageModal);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="col-lg-3 w-100 bg-light fs-1 mr-2 rounded"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "154px",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              setImageUploadData({
                                media_type: "city_image",
                                media_display_order:
                                  cityData?.city_image?.media_display_order,
                                media_reference_id: id,
                                media_reference_type: "City",
                              });
                              setUploadImageModal(!uploadImageModal);
                            }}
                          >
                            +
                          </div>
                        </>
                      )}
                    </div>
                    <div className=" col-md-6 col-lg-8">
                      <label htmlFor="" className=" mt-2 mb-2">
                        {" "}
                        <b>City Banner</b>{" "}
                      </label>
                      {cityData?.city_banner ? (
                        <>
                          <div className=" border position-relative">
                            <img
                              src={cityData?.city_banner}
                              style={{ height: "35vh" }}
                              alt="Banner Image"
                            />
                            <div
                              className="position-absolute "
                              style={{ top: 1, right: 6 }}
                            >
                              <img
                                src="/images/edit.svg"
                                alt=""
                                role="button"
                                onClick={() => {
                                  setImageUploadData({
                                    media_type: "city_banner",
                                    media_display_order: 1,
                                    media_reference_id: id,
                                    media_reference_type: "City",
                                  });
                                  setUploadImageModal(!uploadImageModal);
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="col-lg-3 bg-light fs-1 mr-2 rounded"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "154px",
                              cursor : "pointer"
                            }}
                            onClick={() => {
                              setImageUploadData({
                                media_type: "city_banner",
                                media_display_order: 1,
                                media_reference_id: id,
                                media_reference_type: "City",
                              });
                              setUploadImageModal(!uploadImageModal);
                            }}
                          >
                            +
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-4 border rounded mh-100 scrollbar mt-4"
                  style={{
                    height: "50%",
                  }}
                >
                  <div className=" my-4">
                    <h5>
                      <b>Property List</b>
                    </h5>
                  </div>
                  <div>
                    {!propertyList || propertyList.length <= 0 ? (
                      <>No Property Found</>
                    ) : (
                      <>
                        {propertyList?.map((data, index) => (
                          <div key={index} className=" mt-2 ">
                            <Link
                              to={`/website/city/${id}/property/${data?.id}`}
                              className=" my-5"
                            >
                              {data?.property_name}
                            </Link>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className=" d-flex justify-content-between align-items-center pb-2 mt-4 ">
                  <h5>
                    <b>Frequently Asked Questions</b>
                  </h5>
                  <div
                    className=" basic-butto border p-2 rounded "
                    role="button"
                    onClick={() => {
                      setopenFaqsModal(true);
                      setFaqActionType("create");
                      setFaqsSelectedData(null);
                    }}
                    style={{
                      color: "#FF5700",
                      cursor: "pointer",
                      fontSize: "17px",
                      fontWeight: "800",
                      lineHeight: "23.8px",
                    }}
                  >
                    <svg
                      className={"cursor-pointer"}
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 3.99609V19.9961M4.5 11.9961H20.5"
                        stroke="#FF5700"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>{" "}
                    <span className=" mt-2">Add New Faq</span>
                  </div>
                </div>
                {cityData?.faqs?.map((faqData, index) => (
                  <div
                    className=" border p-2 mt-3 position-relative"
                    key={index}
                  >
                    <div>
                      <b>{faqData?.question}</b>
                    </div>
                    <div className=" mt-1 ">{faqData?.answer}</div>
                    <div
                      className="position-absolute"
                      style={{ top: -14, right: 10 }}
                    >
                      <img
                        src="/images/edit.svg"
                        alt="Edit Icon"
                        className="bg-white"
                        onClick={() => {
                          setopenFaqsModal(true);
                          setFaqActionType("update");
                          setFaqsSelectedData(faqData);
                        }}
                        role="button"
                      />
                      <span className="ml-3 bg-white">
                        <img
                          src="/images/trash-03.png"
                          alt=""
                          style={{ height: "3vh" }}
                          role="button"
                          onClick={() => {
                            setDeleteFaqModal(true);
                            setDeleteFaqModalData({
                              id: faqData?.id,
                              city_id: Number(id),
                            });
                          }}
                        />
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {openUpdateModal && (
                <>
                  <UpdateCity
                    id={id}
                    openModal={openUpdateModal}
                    setOpenModal={setOpenUpdateModal}
                    data={cityData}
                    setFetchData={setCityData}
                  />
                </>
              )}
              {uploadImageModal && (
                <>
                  <UploadImage
                    show={uploadImageModal}
                    setShow={setUploadImageModal}
                    data={imageUploadData}
                    fetchData={fetchData}
                  />
                </>
              )}
              {openFaqsModal && (
                <>
                  <FaqModal
                    openModal={openFaqsModal}
                    setOpenModal={setopenFaqsModal}
                    faqdata={faqSelectedData}
                    actionType={faqActionType}
                    cityId={id}
                    fetchData={fetchData}
                  />
                </>
              )}
              {deleteModal && (
                <>
                  <DeleteImageModal
                    openDeleteModal={deleteModal}
                    data={deleteData}
                    setDeleteModal={setDeleteModal}
                    fetchData={fetchData}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <div className="text-center">
                No data found for the city id = {id}
              </div>
            </>
          )}
          {deletefaqModal && (
            <Modal
              show={deletefaqModal}
              centered
              size="md"
              className="common_modal"
            >
              <Modal.Header>
                <b>Delete Image</b>
                <img
                  src="/images/cross_modal.svg"
                  className="cursor-pointer"
                  alt="Close Icon"
                  onClick={() => {
                    setDeleteFaqModal(false);
                  }}
                />
              </Modal.Header>
              <Modal.Body>Are you sure want to delete the FAQ ?</Modal.Body>
              <Modal.Footer>
                <button
                  className="basic-button"
                  disabled={loadingDeleteFaq}
                  onClick={deleteFaqData}
                >
                  Delete FAQ
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default withDefaultDashBoardLayout(CityDetails);
