import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import "../blog.css";
import {
  fetchBlogAuthers,
  fetchBlogProperty,
  fetchBlogsCategories,
  fetchBlogsCities,
  fetchBlogsList,
  postBlogs,
} from "../../../../helpers/services/admin.services";
import toast from "react-hot-toast";

const CreateBlog = ({ openModal, setOpenModal }) => {
  const [blog, setBlog] = useState({
    title: "",
    slug: "",
    blog_author_id: "",
    blog_category_id: "",
    city_id: "",
    description: "",
    related_blogs: [],
    meta_title: "",
    meta_description: "",
    meta_image_url: "",
    meta_image_alt: "",
    image_url: "",
  });
  const navigate = useNavigate();
  const [selectedBlogs, setSelectedBlogs] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [allAuthers, setAllAuthers] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [metaKey, setMetaKey] = useState("");
  const [metaKeyWords, setMetaKeyWords] = useState([]);

  const deleteMetaKeyword = (ind) => {
    const filtredMeta = metaKeyWords.filter((data, index) => index !== ind);
    setMetaKeyWords(filtredMeta);
  };

  const fetchAllAuthers = async () => {
    try {
      const response = await fetchBlogAuthers();
      if (response.data) {
        const authors = response.data;
        const convertedauthors = authors.map((author) => ({
          label: author.author_name,
          value: author.id,
          image: author.author_image,
        }));
        setAllAuthers((preAllProp) => [...preAllProp, ...convertedauthors]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllCategories = async () => {
    try {
      const response = await fetchBlogsCategories();
      if (response.data) {
        const categories = response.data;
        const convertedcategories = categories.map((category) => ({
          label: category.category_name,
          value: category.id,
        }));
        setAllCategories((preAllProp) => [
          ...preAllProp,
          ...convertedcategories,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchAllCities = async () => {
    try {
      const response = await fetchBlogsCities();
      if (response.data) {
        const cities = response.data;
        const convertedcities = cities.map((city) => ({
          label: city.city_name,
          value: city.id,
        }));
        setAllCities((preAllProp) => [...preAllProp, ...convertedcities]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllBlogList = async () => {
    try {
      const response = await fetchBlogsList();
      if (response.data) {
        const blogs = response.data;
        const convertedblogs = blogs.map((blog) => ({
          label: blog.title,
          value: blog.id,
        }));
        setAllBlogs((preAllProp) => [...preAllProp, ...convertedblogs]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(selectedBlogs);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBlog({
      ...blog,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const data = {
      blog_author_id: blog?.blog_author_id,
      blog_category_id: blog?.blog_category_id,
      slug: blog?.slug,
      city_id: blog?.city_id,
      title: blog?.title,
      image_url: blog?.image_url,
      description: blog?.description,
      related_blogs: selectedBlogs?.map((blg) => blg.value),
      meta: {
        meta_title: blog?.meta_title,
        meta_description: blog?.meta_description,
        meta_keywords: metaKeyWords,
        meta_image: {
          url: blog?.meta_image_url,
          alt: blog?.meta_image_alt,
        },
      },
    };

    try {
      const response = await postBlogs(data);
      if (response.status == 422) {
        return toast.error(response.data.errors);
      }

      if (response.data) {
        toast.success("Blog created please add sections");
        navigate(`/website/blog/${response.data.id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllProperties = async () => {
    try {
      const response = await fetchBlogProperty("", "", "", 1000);
      if (response.data) {
        const properties = response.data;
        const convertedproperties = properties.map((property) => ({
          label: property.property_name,
          value: property.id,
        }));
        setAllProperties((preAllProp) => [
          ...preAllProp,
          ...convertedproperties,
        ]);
      }
    } catch (error) {
      console.error("Error fetching Properties:", error);
    }
  };

  const clearData = () => {
    setBlog({
      title: "",
      slug: "",
      blog_author_id: "",
      blog_category_id: "",
      city_id: "",
      description: "",
      related_blogs: [],
      meta_title: "",
      meta_description: "",
      meta_image_url: "",
      meta_image_alt: "",
    });
  };

  useState(() => {
    fetchAllProperties();
    fetchAllAuthers();
    fetchAllBlogList();
    fetchAllCategories();
    fetchAllCities();
  }, []);

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => setOpenModal(false)}
        centered
        size="lg"
        className="common_modal"
      >
        <Modal.Header className="mx-3">
          <Modal.Title>Create Blog</Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              setOpenModal(false);
              clearData();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="mb-3">
              <a href="https://crm.your-space.in/tp/s3upload/" target="_blank">
                Get Image URL
              </a>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="mb-3 col-md-8 form_control_box">
                    <label htmlFor="">Meta Image Url</label>
                    <input
                      type="text"
                      name="meta_image_url"
                      placeholder="Meta Image Url"
                      value={blog.meta_image_url}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3 col-md-4 form_control_box">
                    <label htmlFor="">Meta Image Alt</label>
                    <input
                      type="text"
                      name="meta_image_alt"
                      placeholder="Meta Image Alt"
                      value={blog.meta_image_alt}
                      onChange={handleInputChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="mb-3 form_control_box">
                  <label htmlFor="">Blog Title</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={blog.title}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="mb-3 form_control_box">
                  <label htmlFor="">Blog slug</label>
                  <input
                    type="text"
                    name="slug"
                    placeholder="Title"
                    value={blog.slug}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="mb-3 form_control_box">
                  <label htmlFor="">Cover Image Url</label>
                  <input
                    type="text"
                    name="image_url"
                    placeholder="Cover Image URL"
                    value={blog?.image_url}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="mb-3 form_control_box">
                  <label htmlFor="">Meta Titile</label>
                  <input
                    type="text"
                    name="meta_title"
                    placeholder="Meta Title"
                    value={blog.meta_title}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6 form_control_box">
                    <label htmlFor="">Select Author</label>
                    <Select
                      options={allAuthers}
                      onChange={(e) => {
                        setBlog({
                          ...blog,
                          blog_author_id: e.value,
                        });
                      }}
                      placeholder="Select Author"
                    />
                  </div>
                  <div className="mb-3 col-md-6 form_control_box">
                    <label htmlFor="">Select Category</label>
                    <Select
                      options={allCategories}
                      onChange={(e) => {
                        setBlog({
                          ...blog,
                          blog_category_id: e.value,
                        });
                      }}
                      placeholder="Select Category"
                    />
                  </div>
                </div>

                <div className="mb-3 form_control_box">
                  <label htmlFor="">Select City</label>
                  <Select
                    options={allCities}
                    onChange={(e) => {
                      setBlog({
                        ...blog,
                        city_id: e.value,
                      });
                    }}
                    placeholder="Select City"
                  />
                </div>
                <div className="form_control_box mb-3">
                  <label htmlFor="">Select Related Blogs</label>
                  <Select
                    options={allBlogs}
                    isMulti
                    value={selectedBlogs}
                    onChange={(e) => setSelectedBlogs(e)}
                    className="mb-3"
                    placeholder="Select related blogs"
                  />
                </div>
                <div className="form_control_box mb-3">
                  <label htmlFor="">Enter Description</label>
                  <textarea
                    placeholder="Enter description"
                    className="form-control"
                    onChange={handleInputChange}
                    name="description"
                    value={blog?.description}
                  />
                </div>
                <div className="form_control_box mb-3">
                  <label htmlFor="">Enter Meta Description</label>
                  <textarea
                    placeholder="Enter meta description"
                    className="form-control"
                    onChange={handleInputChange}
                    name="meta_description"
                    value={blog?.meta_description}
                  />
                </div>
                <div
                  className="mb-3 col-md- form_control_box"
                  style={{ position: "relative" }}
                >
                  <label htmlFor="">Add Keywords</label>
                  <input
                    type="text"
                    name="meta_tag"
                    placeholder="Enter keywords"
                    value={metaKey}
                    onChange={(e) => {
                      setMetaKey(e.target.value)
                    }}
                    className="form-control"
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 35,
                      right: 10,
                      color: "#FF5700",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if(metaKey.trim() == ""){
                        return toast.error("Please enter something")
                      }
                      setMetaKeyWords((preValue) => [...preValue, metaKey]);
                      setMetaKey("");
                    }}
                  >
                    Add Keyword
                  </div>
                </div>
                <div className="d-flex">
                  {metaKeyWords.length > 0 && (
                    <div>
                      {metaKeyWords.map((key, index) => (
                        <div key={index} className=" d-flex ">
                          <div>
                            {" "}
                            <img
                              src="/images/cross_modal.svg"
                              className="cursor-pointer"
                              alt="Close Icon"
                              onClick={() => deleteMetaKeyword(index)}
                            />{" "}
                          </div>
                          <div className=" mt-1 ml-1">{key} </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" onClick={handleSubmit} className="basic-button">
            Save and continue
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateBlog;
