import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { updateCityDetails } from "../../../../helpers/services/websiteServices";

const UpdateCity = ({ id, openModal, setOpenModal, data, setFetchData }) => {
  const [cityData, setCityData] = useState({
    meta_title: data?.meta_data?.meta_title || "",
    meta_description: data?.meta_data?.meta_description || "",
    meta_keywords: data?.meta_data?.meta_keywords || "",
    city_title: data?.city_data?.city_title || "",
    city_description: data?.city_data?.city_description,
  });

  const [loading , setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCityData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const updateData = async () => {
    setLoading(true)
    const datas = {
      meta_data: {
        meta_title: cityData?.meta_title,
        meta_description: cityData?.meta_description,
        meta_keywords: cityData?.meta_keywords,
      },
      city_data: {
        city_title: cityData?.city_title,
        city_description: cityData?.city_description,
      },
    };

    const response = await updateCityDetails(id, datas);
    if (response.status == "success") {
      setFetchData(response.data);
      handleClose();
      toast.success("Data updated");
      setLoading(false)
    } else {
      toast.error("Error while updating data please try again");
      setLoading(false)
    }

  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={handleClose}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title className="underline">
            {data?.city_data?.city_title}
          </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="form_control_box">
            <div className="row">
              <div className=" col-md-6 col-lg-8 col-sm-6">
                <div className="mb-2">
                  <label htmlFor="faq-question" className="form-label">
                    <b>Meta Title</b>
                  </label>
                  <input
                    type="text"
                    id="faq-question"
                    className="form-control mt-1"
                    name="meta_title"
                    onChange={handleChange}
                    value={cityData?.meta_title}
                  />
                </div>
              </div>
              <div className=" col-md-6 col-lg-4 col-sm-6">
                <div className="mb-2">
                  <label htmlFor="faq-question" className="form-label">
                    <b>City Title</b>
                  </label>
                  <input
                    type="text"
                    id="faq-question"
                    className="form-control mt-1"
                    name="city_title"
                    onChange={handleChange}
                    value={cityData?.city_title}
                  />
                </div>
              </div>
            </div>

            <div className="mb-2">
              <label htmlFor="faq-question" className="form-label">
                <b>Meta Description</b>
              </label>
              <textarea
                type="text"
                id="faq-question"
                className="form-control mt-1"
                name="meta_description"
                onChange={handleChange}
                value={cityData?.meta_description}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="faq-question" className="form-label">
                <b>Meta Keywords</b>
              </label>
              <input
                type="text"
                id="faq-question"
                className="form-control mt-1"
                name="meta_keywords"
                onChange={handleChange}
                value={cityData?.meta_keywords}
              />
            </div>
            <div className="mb-2">
              <label htmlFor="faq-question" className="form-label">
                <b>City Description</b>
              </label>
              <textarea
                type="text"
                id="faq-question"
                className="form-control mt-1"
                name="city_description"
                onChange={handleChange}
                value={cityData?.city_description}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className=" basic-button" onClick={updateData} disabled={loading}>
            Update{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpdateCity;
