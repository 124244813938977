import React, { useEffect, useState } from "react";
import withDefaultDashBoardLayout from "../../../../components/layouts/withDefaultDashBoardLayout";
import { Link, useParams } from "react-router-dom";
import {
  deleteInstituteFaq,
  getInstitutionDetails,
} from "../../../../helpers/services/websiteServices";
import UpdateProperty from "../modals/UpdateProperty";
import UploadImage from "../modals/UploadImages";
import DeleteImageModal from "../../DeleteImageModal";
import { Modal } from "react-bootstrap";
import FaqModal from "../modals/FaqModal";
import toast from "react-hot-toast";

const InstitutionDetails = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [propertyData, setPropertyData] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [uploadModal, setUploadImage] = useState(false);
  const [uploadImageData, setUploadImageData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [deletefaqModal, setDeleteFaqModal] = useState(false);
  const [deletefaqModalData, setDeleteFaqModalData] = useState(null);
  const [loadingDeleteFaq, setLoadingDeleteFaq] = useState(false);
  const [openFaqsModal, setopenFaqsModal] = useState(false);
  const [faqSelectedData, setFaqsSelectedData] = useState(null);
  const [faqActionType, setFaqActionType] = useState(null);

  const fetchData = async () => {
    const response = await getInstitutionDetails(id);
    if (response && !response.data.errors) {
      setPropertyData(response.data);
    } else {
      setPropertyData(null);
    }
  };

  const deleteFaqData = async () => {
    setLoadingDeleteFaq(true);
    const deleteData = {
      id: deletefaqModalData?.id,
      institution_id: id,
    };
    const response = await deleteInstituteFaq(deleteData);
    if (response && !response.data.errors && response.status == "success") {
      setPropertyData(response.data);
      toast.success("Faq deleted");
      setDeleteFaqModal(false);
      setDeleteFaqModalData(null);
      setLoadingDeleteFaq(false);
    } else {
      toast.error(response?.data?.errors);
      setLoadingDeleteFaq(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="property-header">
            <div className="right-side-header">
              <div className="d-flex justify-content-between align-items-center pb-2">
                <div className="d-flex align-items-center">
                  <Link to={"/"}>
                    <img src="/images/Back.svg" alt="Back" />
                  </Link>
                  <h2>Website</h2>
                </div>
              </div>
              <div className="breadcum">
                <ul>
                  <li>
                    <a href="#">Website</a> <span>/</span>
                  </li>
                  <li>
                    <Link to={`/website/institution`}>
                      Institution <span> /</span>
                    </Link>
                    {propertyData?.name}
                  </li>
                </ul>
              </div>
            </div>
            {propertyData && (
              <button
                className=" basic-button"
                onClick={() => setOpenUpdateModal(!openUpdateModal)}
              >
                Institution Details
              </button>
            )}
          </div>
          {propertyData ? (
            <>
              <div className="d-flex justify-content-between">
                <label htmlFor="" className=" mt-2">
                  {" "}
                  <b>Institute Image</b>{" "}
                </label>
                <div
                  className=" basic-butto border p-2 rounded "
                  role="button"
                  onClick={() => {
                    setUploadImageData({
                      media_display_order:
                        propertyData?.institute_images[
                          propertyData?.institute_images?.length - 1
                        ]?.media_display_order || 1,
                      media_reference_id: id,
                      media_reference_type: "Institution",
                    });
                    setUploadImage(true);
                  }}
                  style={{
                    color: "#FF5700",
                    cursor: "pointer",
                    fontSize: "17px",
                    fontWeight: "800",
                    lineHeight: "23.8px",
                  }}
                >
                  <svg
                    className={"cursor-pointer"}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5 3.99609V19.9961M4.5 11.9961H20.5"
                      stroke="#FF5700"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>{" "}
                  <span className=" pt-3">Add New Image</span>
                </div>
              </div>
              <div className=" row mt-2">
                {propertyData?.institute_images?.map((datas, index) => (
                  <div
                    className="col-lg-3 mt-2  position-relative "
                    key={index}
                  >
                    <img
                      src={datas?.media_url}
                      alt=""
                      className=" rounded w-100"
                      style={{ height: "30vh" }}
                    />
                    <br />
                    {/* <div className=" text-center">{datas?.media_type}</div> */}
                    <div
                      className="position-absolute "
                      style={{ top: 1, right: 15 }}
                    >
                      <img
                        src="/images/trash-03.png"
                        style={{ height: "3vh" }}
                        alt=""
                        role="button"
                        onClick={() => {
                          setDeleteData({
                            id: datas?.id,
                            is_active: false,
                          });
                          setDeleteModal(true);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div>
                <div className=" d-flex justify-content-between align-items-center pb-2 mt-4 ">
                  <h5>
                    <b>Frequently Asked Questions</b>
                  </h5>
                  <div
                    className=" basic-butto border p-2 rounded "
                    role="button"
                    onClick={() => {
                      setopenFaqsModal(true);
                      setFaqActionType("create");
                      setFaqsSelectedData(null);
                    }}
                    style={{
                      color: "#FF5700",
                      cursor: "pointer",
                      fontSize: "17px",
                      fontWeight: "800",
                      lineHeight: "23.8px",
                    }}
                  >
                    <svg
                      className={"cursor-pointer"}
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5 3.99609V19.9961M4.5 11.9961H20.5"
                        stroke="#FF5700"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>{" "}
                    <span className=" mt-2">Add New Faq</span>
                  </div>
                </div>
                {propertyData?.faqs?.map((faqData, index) => (
                  <div
                    className=" border p-2 mt-3 position-relative"
                    key={index}
                  >
                    <div>
                      <b>{faqData?.question}</b>
                    </div>
                    <div className=" mt-1 ">{faqData?.answer}</div>
                    <div
                      className="position-absolute"
                      style={{ top: -14, right: 10 }}
                    >
                      <img
                        src="/images/edit.svg"
                        alt="Edit Icon"
                        className="bg-white"
                        onClick={() => {
                          setopenFaqsModal(true);
                          setFaqActionType("update");
                          setFaqsSelectedData(faqData);
                        }}
                        role="button"
                      />
                      <span className="ml-3 bg-white">
                        <img
                          src="/images/trash-03.png"
                          alt=""
                          style={{ height: "3vh" }}
                          role="button"
                          onClick={() => {
                            setDeleteFaqModal(true);
                            setDeleteFaqModalData({
                              id: faqData?.id,
                              property_id: Number(id),
                            });
                          }}
                        />
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {openUpdateModal && (
                <UpdateProperty
                  openModal={openUpdateModal}
                  setOpenModal={setOpenUpdateModal}
                  data={propertyData}
                  instituteId={id}
                  fetchData={fetchData}
                />
              )}
              {uploadModal && (
                <UploadImage
                  setShow={setUploadImage}
                  show={uploadModal}
                  data={uploadImageData}
                  fetchData={fetchData}
                />
              )}
              {deleteModal && (
                <DeleteImageModal
                  openDeleteModal={deleteModal}
                  data={deleteData}
                  setDeleteModal={setDeleteModal}
                  fetchData={fetchData}
                />
              )}

              {openFaqsModal && (
                <FaqModal
                  openModal={openFaqsModal}
                  setOpenModal={setopenFaqsModal}
                  faqdata={faqSelectedData}
                  actionType={faqActionType}
                  cityId={id}
                  id={id}
                  fetchData={fetchData}
                />
              )}

              {deletefaqModal && (
                <Modal
                  show={deletefaqModal}
                  centered
                  size="md"
                  className="common_modal"
                >
                  <Modal.Header>
                    <b>Delete Image</b>
                    <img
                      src="/images/cross_modal.svg"
                      className="cursor-pointer"
                      alt="Close Icon"
                      onClick={() => {
                        setDeleteFaqModal(false);
                      }}
                    />
                  </Modal.Header>
                  <Modal.Body>Are you sure want to delete the FAQ ?</Modal.Body>
                  <Modal.Footer>
                    <button
                      className="basic-button"
                      disabled={loadingDeleteFaq}
                      onClick={deleteFaqData}
                    >
                      Delete FAQ
                    </button>
                  </Modal.Footer>
                </Modal>
              )}
            </>
          ) : (
            <>No Institution found</>
          )}
        </>
      )}
    </>
  );
};

export default withDefaultDashBoardLayout(InstitutionDetails);
