import { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { dateFormat } from "../../../helpers/common/common.function";
import {
  confirmCheckInStudent,
  createBondDetails,
  disableBond,
  enableAssetReverification,
  residentBondEligibilityCheck,
  residentBondStatus,
} from "../../../helpers/services/api.services";
import toast from "react-hot-toast";
import "../../../css/onboarding-details-tab.css";
import GlobalStateContext from "../../../GlobalStateContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import UpdateBondDetails from "./modals/UpdateBondDetails";

function PocCheckinTab({ residentInfo, onboardingInfo, onAction }) {
  const { roles } = useContext(GlobalStateContext);
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const [isStudentCheckedIn, setStudentCheckIn] = useState(false);
  const [isAllAssetChecked, setIsAllAssetChecked] = useState(false);
  const [residentRoomAssetDetails, setResidentRoomAssetDetails] = useState([]);
  const [residentRoomPendingAssetDetails, setResidentRoomPendingAssetDetails] =
    useState([]);
  const [
    residentRoomDeliveredAssetDetails,
    setResidentRoomDeliveredAssetDetails,
  ] = useState([]);
  const [agreementStatus, setAgreementStatus] = useState({
    signed_by_guardian: false,
    signed_by_resident: false,
  });
  const [loadingbond, setLoadingBond] = useState(true);
  const [bondEligibilityData, setBondEligibilityData] = useState(null);
  const [showBondModal, setShowBondModal] = useState(false);
  const [issueBondModal, setIssueBondModal] = useState(false);
  const [disableModal, setDisabledModal] = useState(false);

  const initialValues = {
    pan_number: "",
    gross_income: "",
    employment_type: "",
    employment_proof_type: "",
    employment_proof_image: null,
    relation: "",
  };

  const [submitFormLoader, setSubmitformLoader] = useState(false);
  const [eligbilityCheckLoader, setEligibilityCheckLoader] = useState(false);
  const [disableBondLoader, setDisableBondLoader] = useState(false);
  const [showupdateBondModal, setShowUpdateBondModal] = useState(false);

  // Status: not_delivered, pending, delivered
  const [residentAssetStatus, setResidentAssetStatus] = useState(null);

  const fetchResidentBondData = async () => {
    setLoadingBond(true);
    const response = await residentBondStatus(onboardingInfo?.id);
    if (response && response?.data) {
      setBondEligibilityData(response?.data);
      setLoadingBond(false);
    } else {
      setBondEligibilityData(null);
      setLoadingBond(false);
    }
  };

  useEffect(() => {
    if (onboardingInfo?.id) {
      fetchResidentBondData();
    }
  }, [onboardingInfo]);

  const handleCloseBondModal = () => {
    setShowBondModal(false);
  };

  const validationSchema = Yup.object().shape({
    pan_number: Yup.string()
      .required("PAN number is required")
      .max(10, "Enter Valid PAN Number"),
    gross_income: Yup.number().required("Gross income is required").positive(),
    employment_type: Yup.string().required("Employment type is required"),
    employment_proof_type: Yup.string().required("Proof type is required"),
    employment_proof_image: Yup.mixed().required("Proof image is required"),
    relation: Yup.string().required("Relation is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSubmitformLoader(true);
      values.applicant_reference_id =
        bondEligibilityData?.applicant?.applicant_reference_id;
      values.applicant_reference_type =
        bondEligibilityData?.applicant?.applicant_reference_type;
      const formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }

      const formResponse = await createBondDetails(
        onboardingInfo?.id,
        formData
      );

      if (formResponse) {
        console.log(formResponse);
        setSubmitformLoader(false);
        if (formResponse?.status == "success") {
          toast.success("Application submit successfull please issue the bond");
          fetchResidentBondData();
          setShowBondModal(false);
        } else {
          setSubmitformLoader(false);
          toast.error(formResponse?.response?.data?.errors);
        }
      }

      setSubmitformLoader(false);

      // Handle form submission, e.g., API call
      // await submitFormData(formData);
    },
  });

  const issueBondHanldle = async () => {
    setEligibilityCheckLoader(true);
    const eligibilityResponse = await residentBondEligibilityCheck(
      onboardingInfo?.id
    );
    if (eligibilityResponse && eligibilityResponse?.data) {
      toast.success(
        "The bond fee has been generated. Please ask the resident to make the payment through the app "
      );
      setEligibilityCheckLoader(false);
      setIssueBondModal(false);
      setShowBondModal(false);
      fetchResidentBondData();
    } else {
      toast.error(eligibilityResponse?.response?.data?.errors);
      setEligibilityCheckLoader(false);
    }
  };

  const disableBondHanldle = async () => {
    setDisableBondLoader(true);
    const disableBondResponse = await disableBond(onboardingInfo?.id);
    if (disableBondResponse && disableBondResponse?.data) {
      toast.success("Bond Disabled successfully");
      setDisableBondLoader(false);
      setIssueBondModal(false);
      setShowBondModal(false);
      fetchResidentBondData();
      setDisabledModal(false);
    } else {
      toast.error(disableBondResponse?.response?.data?.errors);
      setDisableBondLoader(false);
    }
  };

  useEffect(() => {
    if (onboardingInfo) {
      // Update check-in status
      setStudentCheckIn(onboardingInfo?.checked_in);

      if (
        onboardingInfo?.resident_room_asset_details &&
        onboardingInfo?.resident_room_asset_details.length > 0
      ) {
        handleResidentRoomAssetDetails();
      }

      if (onboardingInfo && onboardingInfo?.agreement_details) {
        setAgreementStatus(onboardingInfo?.agreement_details);
      }
    }
  }, [onboardingInfo]);

  useEffect(() => {
    let all_verified = true;
    onboardingInfo?.resident_room_asset_details?.map((details) => {
      if (!details.is_verified) {
        all_verified = false;
      }
    });

    if (
      all_verified &&
      onboardingInfo?.resident_room_asset_details?.length > 0
    ) {
      setIsAllAssetChecked(true);
    }
  }, [onboardingInfo]);

  // MODAL Actions
  const handleCloseCheckInModal = () => setShowCheckInModal(false);
  const handleShowCheckInModal = () => setShowCheckInModal(true);

  const handleResidentRoomAssetDetails = () => {
    // Set assets details in state
    setResidentRoomAssetDetails(onboardingInfo?.resident_room_asset_details);

    // Assets filtering by pending and delivered
    let fetchPendingAssets = onboardingInfo?.resident_room_asset_details.filter(
      (asset) => asset?.is_verified === false
    );
    setResidentRoomPendingAssetDetails(fetchPendingAssets);

    let fetchDeliveredAssets =
      onboardingInfo?.resident_room_asset_details.filter(
        (asset) => asset?.is_verified === true
      );
    setResidentRoomDeliveredAssetDetails(fetchDeliveredAssets);

    // Assets Checklist Status
    let assetStatus = null;

    if (
      onboardingInfo?.resident_room_asset_details.length ===
      fetchPendingAssets.length
    )
      assetStatus = "not_delivered";
    else if (
      fetchDeliveredAssets.length <
      onboardingInfo?.resident_room_asset_details.length
    )
      assetStatus = "pending";
    else if (
      onboardingInfo?.resident_room_asset_details.length ===
      fetchDeliveredAssets.length
    )
      assetStatus = "delivered";

    setResidentAssetStatus(assetStatus);
  };

  const handleConfirmCheckinStudent = async () => {
    let is_apiCall_valid = false;
    if (onboardingInfo?.resident_type == "working_professional") {
      is_apiCall_valid = agreementStatus.signed_by_resident ? true : false;
    } else {
      is_apiCall_valid =
        agreementStatus.signed_by_guardian && agreementStatus.signed_by_resident
          ? true
          : false;
    }
    // if (onboardingInfo && onboardingInfo?.id && (agreementStatus.signed_by_guardian && agreementStatus.signed_by_resident)) {
    if (onboardingInfo && onboardingInfo?.id && is_apiCall_valid) {
      // console.log("Checkin api call")
      let response = await confirmCheckInStudent(onboardingInfo?.id);

      if (response && response.status === "success") {
        // Reload the APIs
        await onAction();
      }else{
        toast.error(response?.data?.error || "Some thing went wrong please try again")
      }
    } else {
      toast.error(
        "You can not check-in because student agreement is not completed."
      );
    }
    handleCloseCheckInModal();
  };

  // ENABLE ASSET RE-VERIFICATION
  const handleEnableAssetReverification = async () => {
    try {
      if (
        onboardingInfo &&
        onboardingInfo?.id &&
        residentRoomPendingAssetDetails
      ) {
        const payload = {
          resident_room_asset_ids: residentRoomPendingAssetDetails.map(
            (data) => data.id
          ),
        };
        let response = await enableAssetReverification(
          onboardingInfo?.id,
          payload
        );
        if (response && response.status === "success") {
          toast.success("Assets sent for reverification successfully");
          await onAction();
        }
      }
      // console.log(" -- ",residentRoomPendingAssetDetails)
      // console.log("In normal tab")
    } catch (error) {
      toast.error("Error while sending assets for reverification");
    }
  };

  return (
    <>
      {!loadingbond &&
        bondEligibilityData &&
        bondEligibilityData?.show_bond == true && (
          <>
            {roles?.super_admin ||
            roles?.tech_admin ||
            roles?.finance_manager ||
            roles?.warden ||
            roles?.admin || roles?.property_manager ||
            roles?.finance_head ? (
              <>
                <div
                  className=""
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <div
                    className="download_icn_txt"
                    onClick={() => setDisabledModal(true)}
                    style={{ cursor: "pointer", display: "flex" }}
                  >
                    <img
                      src="/images/ban.svg"
                      className="pr-1"
                      alt=""
                      style={{
                        height: "14px",
                        marginTop: "5px",
                        marginRight: "4px",
                      }}
                    />
                    Cancel bond request
                  </div>
                </div>
                {/* <div className=" text-danger">*Disabling bond add security deposit </div> */}
              </>
            ) : null}
          </>
        )}

      {residentRoomAssetDetails &&
      residentRoomAssetDetails.length > 0 &&
      onboardingInfo?.checked_in &&
      onboardingInfo?.enable_pending_assets_button ? (
        <>
          <div className="table_main_tit d-flex justify-content-between align-items-center pb-20">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Asset checklist</h2>
              <div
                className={
                  residentAssetStatus === "not_delivered"
                    ? "red_round_check"
                    : residentAssetStatus === "pending"
                    ? "orange_round_check"
                    : residentAssetStatus === "delivered"
                    ? "green_round_check"
                    : ""
                }
              >
                <span></span>
                <p>
                  {residentAssetStatus === "not_delivered"
                    ? "All assets not delivered"
                    : residentAssetStatus === "pending"
                    ? "Pending Acknowledge"
                    : residentAssetStatus === "delivered"
                    ? "All assets delivered"
                    : ""}
                </p>
              </div>
            </div>
            <span className="date-updated-text">
              Last updated :{" "}
              {onboardingInfo?.asset_list_last_updated
                ? dateFormat(onboardingInfo.asset_list_last_updated)
                : "--"}
            </span>
          </div>
          <div className="exit_tab_box">
            {residentRoomPendingAssetDetails &&
            residentRoomPendingAssetDetails.length > 0 ? (
              <div className="brd_box_card check_tab_list">
                <div className="box_head">
                  <div className="d-flex align-items-center">
                    <h2>
                      {" "}
                      <img
                        src="/images/chart.svg"
                        className="pr-2"
                        alt=""
                      />{" "}
                      pending assets
                    </h2>
                  </div>
                </div>
                <div className="exit_tab_box">
                  <div className="box_contain ">
                    <div className="info_box check_text">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          {residentRoomPendingAssetDetails.map(
                            (asset, index) => (
                              <div
                                className="d-flex align-items-center info_width"
                                key={index}
                              >
                                <div className="info_img">
                                  {/* <img src={asset?.asset_url} className="pr-2 asset-image" alt={asset?.asset_name} /> */}
                                  <img
                                    src="/images/exclam.png"
                                    className="pr-2 asset-image"
                                    alt={asset?.asset_name}
                                  />
                                </div>
                                <div>
                                  <h4>{asset?.asset_name}</h4>
                                  <span>₹{asset?.amount || "--"}</span>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        {roles?.super_admin ||
                        roles?.admin || roles?.property_manager ||
                        roles?.warden ||
                        roles?.finance_manager ||
                        roles?.finance_head ||
                        roles?.cfo ? (
                          <button
                            className="orange_brd_button"
                            onClick={handleEnableAssetReverification}
                          >
                            Get Pending Assets Acknowledged
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {residentRoomDeliveredAssetDetails &&
            residentRoomDeliveredAssetDetails.length > 0 ? (
              <div className="brd_box_card check_tab_list">
                <div className="box_head">
                  <div className="d-flex align-items-center">
                    <h2>
                      <img src="/images/truck.svg" className="pr-2" alt="" />{" "}
                      delivered assets
                    </h2>
                  </div>
                </div>
                <div className="exit_tab_box">
                  <div className="box_contain ">
                    <div className="info_box check_text">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          {residentRoomDeliveredAssetDetails.map(
                            (asset, index) => (
                              <div
                                className="d-flex align-items-center info_width"
                                key={index}
                              >
                                <div className="info_img">
                                  {/* <img src={asset?.asset_url} className="pr-2 asset-image" alt={asset?.asset_name} /> */}
                                  <img
                                    src="/images/exclam.png"
                                    className="pr-2 asset-image"
                                    alt={asset?.asset_name}
                                  />
                                </div>
                                <div>
                                  <h4>{asset?.asset_name}</h4>
                                  <span>₹20,000</span>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <>
          <div className="w460">
            <div className="check_img">
              <img
                src={
                  isAllAssetChecked
                    ? "/images/room_confirmed.png"
                    : "/images/checkin.png"
                }
                alt=""
              />
            </div>
            {isStudentCheckedIn ? (
              <>
                <div className="disc_text">
                  The resident has checked in on{" "}
                  {dateFormat(onboardingInfo?.moving_date)}.<br />{" "}
                  {isAllAssetChecked
                    ? "Asset confirmed by resident"
                    : "Asset confirmation is pending "}
                </div>
              </>
            ) : (
              <>
                {!loadingbond && bondEligibilityData ? (
                  <>
                    {bondEligibilityData?.show_bond == false ? (
                      <>
                        <div className="small_box_center">
                          <h3>Expected Check-in date</h3>
                          <h2>
                            {onboardingInfo?.moving_date
                              ? dateFormat(onboardingInfo?.moving_date)
                              : "--"}
                          </h2>
                        </div>

                        <div className="disc_text">
                          If the student has checked-in physically, please
                          initiate the asset process
                        </div>

                        { ( onboardingInfo?.id && (roles?.super_admin ||
                    roles?.admin || roles?.property_manager ||
                    roles?.warden ||
                    roles?.finance_manager ||
                    roles?.finance_head ||
                    roles?.cfo )) ? (
                          <div className="d-flex justify-content-center">
                            <button
                              className="basic-button p-18-32-button"
                              onClick={handleShowCheckInModal}
                              style={{
                                marginBottom : "5vh"
                            }}
                            >
                              <img
                                src="/images/plus-circle.svg"
                                className="pr-1"
                                alt=""
                              />{" "}
                              Check-in Student
                            </button>
                          </div>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {!bondEligibilityData?.applicant?.filled ? (
                          <>
                            {roles?.super_admin ||
                            roles?.admin || roles?.property_manager ||
                            roles?.warden ||
                            roles?.finance_manager ||
                            roles?.finance_head ||
                            roles?.cfo ? (
                              <div className="d-flex justify-content-center">
                                <button
                                  className="basic-button p-18-32-button"
                                  onClick={() => setShowBondModal(true)}
                                >
                                  <img
                                    src="/images/plus-circle.svg"
                                    className="pr-1"
                                    alt=""
                                  />{" "}
                                  Initiate Bond Process
                                </button>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          <>
                            <div className=" d-flex">
                              {roles?.super_admin ||
                              roles?.admin || roles?.property_manager ||
                              roles?.warden ||
                              roles?.finance_manager ||
                              roles?.finance_head ||
                              roles?.cfo ? (
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="edit_button"
                                    onClick={() => setIssueBondModal(true)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {/* <img
                                    src="/images/plus-circle.svg"
                                    className="pr-1"
                                    alt=""
                                  />{" "} */}
                                    CHECK ELIGIBILITY
                                  </div>
                                </div>
                              ) : null}
                              {roles?.super_admin ||
                              roles?.admin || roles?.property_manager ||
                              roles?.warden ||
                              roles?.finance_manager ||
                              roles?.finance_head ||
                              roles?.cfo ? (
                                <div className="d-flex justify-content-center mx-3">
                                  <button
                                    className="basic-button p-18-32-button"
                                    onClick={() => setShowUpdateBondModal(true)}
                                  >
                                    <img
                                      src="/images/plus-circle.svg"
                                      className="pr-1"
                                      alt=""
                                    />{" "}
                                    Reupload details
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="small_box_center">
                      <h3>Expected Check-in date</h3>
                      <h2>
                        {onboardingInfo?.moving_date
                          ? dateFormat(onboardingInfo?.moving_date)
                          : "--"}
                      </h2>
                    </div>

                    <div className="disc_text">
                      If the student has checked-in physically, please initiate
                      the asset process
                    </div>
                    { ( onboardingInfo?.id && (roles?.super_admin ||
                    roles?.admin || roles?.property_manager ||
                    roles?.warden ||
                    roles?.finance_manager ||
                    roles?.finance_head ||
                    roles?.cfo ))? (
                      <div className="d-flex justify-content-center">
                        <button
                          className="basic-button p-18-32-button"
                          onClick={handleShowCheckInModal}
                        >
                          <img
                            src="/images/plus-circle.svg"
                            className="pr-1"
                            alt=""
                          />{" "}
                          Check-in Student
                        </button>
                      </div>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}

      {/* CHECK IN MODAL */}
      <Modal
        show={showCheckInModal}
        onHide={handleCloseCheckInModal}
        centered
        size="sm"
        className="check_popup"
      >
        <Modal.Body>
          <div className="head_part_date_modal">
            <span className="close_modal" onClick={handleCloseCheckInModal}>
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <h2>Student check in</h2>
            <p>Are you sure the student has checked in?</p>
          </div>
          <div className="modal_btn_footer pt-5">
            <button
              className="orange_brd_button p-12-16-button mr-3"
              onClick={handleCloseCheckInModal}
            >
              CANCEL
            </button>
            <button
              className="basic-button"
              onClick={handleConfirmCheckinStudent}
            >
              YES, CHECKED IN
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={issueBondModal}
        onHide={() => {
          setIssueBondModal(false);
          setEligibilityCheckLoader(false);
        }}
        centered
        size="sm"
        className="check_popup"
      >
        <Modal.Body>
          <div className="head_part_date_modal">
            <span
              className="close_modal"
              onClick={() => setIssueBondModal(false)}
            >
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <h2>Bond Eligibility Check </h2>
            <p>Are you sure want to continue with eligibility check ?</p>
          </div>
          <div className="modal_btn_footer pt-5">
            <button
              className="orange_brd_button p-12-16-button mr-3"
              onClick={() => {
                setIssueBondModal(false);
                setEligibilityCheckLoader(false);
              }}
            >
              CANCEL
            </button>
            <button
              className="basic-button"
              onClick={issueBondHanldle}
              disabled={eligbilityCheckLoader}
            >
              {eligbilityCheckLoader ? "CHECKING.." : "YES, PROCEED"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={disableModal}
        onHide={() => setDisabledModal(false)}
        centered
        size="sm"
        className="check_popup"
      >
        <Modal.Body>
          <div className="head_part_date_modal">
            <span
              className="close_modal"
              onClick={() => setDisabledModal(false)}
            >
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <h2>Skip Bond Process </h2>
            <p>
              {" "}
              <b>Are you sure want to skip bond process ?</b>{" "}
            </p>
            <p> *Skip bond process and continue with SD </p>
          </div>
          <div className="modal_btn_footer pt-5">
            <button
              className="orange_brd_button p-12-16-button mr-3"
              onClick={() => setDisabledModal(false)}
            >
              CANCEL
            </button>
            <button
              className="basic-button"
              onClick={disableBondHanldle}
              disabled={disableBondLoader}
            >
              {disableBondLoader ? "CHECKING.." : "YES, ADD SD BILL"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showBondModal}
        onHide={
          !submitFormLoader && !eligbilityCheckLoader && handleCloseBondModal
        }
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>Fill Resident Bond Details</Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={() => {
              if (!submitFormLoader && !eligbilityCheckLoader) {
                handleCloseBondModal();
                formik.resetForm();
              }
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="name">
                    Applicant Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Reference Type"
                    className="form-control disabled-input"
                    value={bondEligibilityData?.applicant?.name}
                    disabled={true}
                    style={{ cursor: "not-allowed" }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="aadhaar_no">
                    Applicant Aadhar Number
                  </label>
                  <input
                    type="text"
                    id="aadhaar_no"
                    name="aadhaar_no"
                    placeholder="Enter Reference Type"
                    className="form-control"
                    value={bondEligibilityData?.applicant?.aadhaar_no}
                    disabled={true}
                    style={{ cursor: "not-allowed" }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label
                    className="form-label"
                    htmlFor="applicant_reference_type"
                  >
                    Applicant Reference Type
                  </label>
                  <input
                    type="text"
                    id="applicant_reference_type"
                    name="applicant_reference_type"
                    placeholder="Enter Reference Type"
                    className="form-control"
                    value={
                      bondEligibilityData?.applicant?.applicant_reference_type
                    }
                    disabled={true}
                    style={{ cursor: "not-allowed" }}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="pan_number">
                    PAN Number
                  </label>
                  <input
                    type="text"
                    id="pan_number"
                    name="pan_number"
                    placeholder="Enter PAN Number"
                    className="form-control"
                    value={formik.values.pan_number.toUpperCase()}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "pan_number",
                        e.target.value.toUpperCase()
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.pan_number && formik.errors.pan_number ? (
                    <div className="text-danger" style={{ fontSize: "15px" }}>
                      {formik.errors.pan_number}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="gross_income">
                    Gross Income (monthly)
                  </label>
                  <input
                    type="text"
                    id="gross_income"
                    name="gross_income"
                    placeholder="Enter Gross Income"
                    className="form-control"
                    value={formik.values.gross_income}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.gross_income && formik.errors.gross_income ? (
                    <div className="text-danger">
                      {formik.errors.gross_income}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="employment_type">
                    Employment Type
                  </label>
                  <select
                    id="employment_type"
                    name="employment_type"
                    className="form-control"
                    value={formik.values.employment_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select employment type" />
                    <option value="Salaried" label="Salaried" />
                    <option
                      value="Salaried - Yet to join"
                      label="Salaried - Yet to join"
                    />
                    <option value="Self Employed" label="Self Employed" />
                  </select>
                  {formik.touched.employment_type &&
                  formik.errors.employment_type ? (
                    <div className="text-danger">
                      {formik.errors.employment_type}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="relation">
                    Relation
                  </label>
                  <select
                    id="relation"
                    name="relation"
                    className="form-control"
                    value={formik.values.relation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {bondEligibilityData?.applicant?.relation === false ? (
                      <>
                        <option value="" label="Select relation" />
                        <option value="Self" label="Self" />
                      </>
                    ) : (
                      <>
                        <option value="" label="Select relation" />
                        <option value="Father" label="Father" />
                        <option value="Mother" label="Mother" />
                        <option value="Brother" label="Brother" />
                        <option value="Sister" label="Sister" />
                        <option value="Sibling" label="Sibling" />
                      </>
                    )}
                  </select>
                  {formik.touched.relation && formik.errors.relation ? (
                    <div className="text-danger">{formik.errors.relation}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6 col-lg-4 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="employment_proof_type">
                    Employment Proof Type
                  </label>
                  <select
                    id="employment_proof_type"
                    name="employment_proof_type"
                    className="form-control"
                    value={formik.values.employment_proof_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select proof type" />
                    <option value="offer_letter" label="Offer Letter" />
                    <option value="Office Id" label="Office Id" />
                    <option value="Salary Slip" label="Salary Slip" />
                    <option value="Bank Statement" label="Bank Statement" />
                  </select>
                  {formik.touched.employment_proof_type &&
                  formik.errors.employment_proof_type ? (
                    <div className="text-danger">
                      {formik.errors.employment_proof_type}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-12 col-lg-12 col-sm-12">
                <div className="form_control_box">
                  <label
                    className="form-label"
                    htmlFor="employment_proof_image"
                  >
                    Employment Proof Image
                  </label>
                  <input
                    type="file"
                    id="employment_proof_image"
                    accept="image/*"
                    name="employment_proof_image"
                    className="form-control"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "employment_proof_image",
                        event.currentTarget.files[0]
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.employment_proof_image &&
                  formik.errors.employment_proof_image ? (
                    <div className="text-danger">
                      {formik.errors.employment_proof_image}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {/* {roles?.super_admin ||
            roles?.tech_admin ||
            roles?.finance_manager ||
            roles?.finance_head ? (
              <>
                <div className="" onClick={() => setDisabledModal(true)}>
                <div className=" edit_button" style={{ cursor: "pointer" }}>
                  Disable Bond
                </div>
              </div>
              <div className=" text-danger">*Disabling bond add security deposit </div>
              </>
            ) : null} */}

            <div className="modal_btn_footer pt-5">
              <button
                className="orange_brd_button p-12-16-button mr-3"
                onClick={() => {
                  if (!submitFormLoader && !eligbilityCheckLoader) {
                    handleCloseBondModal();
                    formik.resetForm();
                  }
                }}
              >
                CANCEL
              </button>
              <button
                className="basic-button"
                type="submit"
                disabled={submitFormLoader || eligbilityCheckLoader}
              >
                {submitFormLoader &&
                  !eligbilityCheckLoader &&
                  "SUBMITTING....."}
                {!submitFormLoader &&
                  !eligbilityCheckLoader &&
                  !bondEligibilityData?.applicant?.filled &&
                  "SUBMIT DETAILS"}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {showupdateBondModal && (
        <UpdateBondDetails
          showModal={showupdateBondModal}
          setShowModal={setShowUpdateBondModal}
          details={bondEligibilityData}
          id={onboardingInfo?.id}
          fetchData={fetchResidentBondData}
        />
      )}
    </>
  );
}

export default PocCheckinTab;