import React, { useEffect, useState } from "react";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import { Link, useNavigate } from "react-router-dom";
import { getCityList } from "../../../helpers/services/websiteServices";
import DataTable from "react-data-table-component";

const CityPage = () => {
  const [cityData, setCityData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const fetchData = async () => {
    setLoading(true);
    const response = await getCityList();
    if (response && response.data && response.data.length > 0) {
      setCityData(response.data);
    } else {
      setCityData([]);
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.city_name,
      width: "20%",
      sortable: true,
      sortField: "city_name",
    },
    {
      name: "City Code",
      selector: (row) => row.city_code,
      width: "20%",
      sortable: true,
      sortField: "city_code",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      width: "40%",
      sortable: true,
      sortField: "description",
    },
    {
      name: "Is Active",
      selector: (row) => row.is_active,
      width: "17%",
      cell: (row) => (<>
        <div className="text-center">
            {row.is_active ? "True" : "False"}
        </div>
      </>),
    },
    {
      name: "",
      selector: (row) => row?.id,
      width: "3%",
      cell: (row) => (
        <Link to={`/website/city/${row?.id}`}>
          <img src="/images/right_arrow.svg" alt="Details Icon" />
        </Link>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/website/city"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Website</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Website</a> <span>/</span>
              </li>
              <li>City</li>
            </ul>
          </div>
        </div>
      </div>
      <div className=" sr_data_table">
        <DataTable
          columns={columns}
          data={cityData}
          progressPending={loading}
          fixedHeader={true}
          fixedHeaderScrollHeight={"430px"}
          pagination
          onRowClicked={(data)=> navigate(`/website/city/${data.id}`)}
        />
      </div>
    </>
  );
};

export default withDefaultDashBoardLayout(CityPage);
