import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import styles from "../../../revenue/revenueBulkUploadPage/modals/UploadBulkModal/index.module.scss";
import axios from "axios";
import FormData from "form-data";
import { uploadImage } from "../../../../helpers/services/websiteServices";
import toast from "react-hot-toast";

const UploadImage = ({ show, setShow, data , fetchData }) => {
  const [loading, setLoading] = useState(false);
  const [mediaName, setMediaName] = useState("");
  const [uploadFile, setUploadFile] = useState({
    filename: "",
    value: "",
    count: 0,
  });

  const [mediaType, setMediaType] = useState("default_image");

  const fileRef = useRef(null);

  const onOpenFile = () => {
    fileRef.current.click();
  };  

  const close = () => {
    setUploadFile({
      filename: "",
      value: "",
      count: 0,
    });
    setMediaName("");
    setMediaType("default_image");
    setShow(false);
  };

  const onFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploadFile({
        filename: file.name,
        value: file,
        count: uploadFile.count + 1,
      });
    }
  };

  const onSubmit = async () => {
    if (!uploadFile.value) return;

    setLoading(true);
    const formData = new FormData();
    formData.append("media_image", uploadFile.value);
    formData.append("media_name", mediaName);
    formData.append("media_type", mediaType);
    formData.append("media_display_order", data.media_display_order + 1 || "1");
    formData.append("media_reference_type", data.media_reference_type);
    formData.append("media_reference_id", data.media_reference_id);
    formData.append("is_active", data.is_active || "true");
    formData.append("media_tag", data.media_tag || "image");
    formData.append("optimized", "true");

    const response = await uploadImage(formData);

    if (response.status === "success") {
      toast.success("Image uploaded");
      fetchData()
      setLoading(false);
      close();
    } else {
      toast.error(`${response.data.error}`);
      setLoading(false);
    }
  };

  const mediaTypeOptions = [
    { value: "default_image", label: "Default Image" },
    { value: "room_view", label: "Room View" },
    { value: "bathroom_view", label: "Bathroom View" },
    { value: "cafeteria_view", label: "Cafeteria View" },
    { value: "common_area", label: "Common Area" },
    { value: "gym_area", label: "Gym Area" },
    { value: "seo_image", label: "SEO Image" },
    { value: "facade", label: "Facade" },
  ];

  return (
    <div>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        dialogClassName={styles["dialog-container"]}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={styles["#modal-title"]}>Upload Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form_control_box w-100 mb-3">
            <input
              type="text"
              className=" w-100"
              placeholder="Enter Media Name"
              onChange={(e) => setMediaName(e.target.value)}
              value={mediaName}
            />
          </div>
          <div className="form_control_box w-100 mb-3">
            <Select
              options={mediaTypeOptions}
              onChange={(selectedOption) => setMediaType(selectedOption.value)}
              defaultValue={mediaTypeOptions.find(option => option.value === "default_image")}
            />
          </div>
          <div className={styles["body-content"]}>
            <div>
              <input
                type="file"
                className="d-none"
                accept="image/*"
                ref={fileRef}
                onChange={onFileChange}
                key={"fileInput_" + uploadFile.count}
              />
              <div className={styles["upload-container"]} onClick={onOpenFile}>
                <div className="" style={{ marginTop: "-3px" }}>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.3304 1.89124V5.33335C12.3304 5.80006 12.3304 6.03342 12.4212 6.21168C12.5011 6.36848 12.6286 6.49596 12.7854 6.57586C12.9637 6.66669 13.197 6.66669 13.6637 6.66669H17.1059M12.3304 1.66663H7.99707C6.59694 1.66663 5.89687 1.66663 5.36209 1.93911C4.89169 2.17879 4.50924 2.56124 4.26955 3.03165C3.99707 3.56643 3.99707 4.26649 3.99707 5.66663V14.3333C3.99707 15.7334 3.99707 16.4335 4.26955 16.9683C4.50924 17.4387 4.89169 17.8211 5.36209 18.0608C5.89687 18.3333 6.59694 18.3333 7.99707 18.3333H13.3304C14.7305 18.3333 15.4306 18.3333 15.9654 18.0608C16.4358 17.8211 16.8182 17.4387 17.0579 16.9683C17.3304 16.4335 17.3304 15.7334 17.3304 14.3333V6.66663L12.3304 1.66663Z"
                      stroke="#0F7691"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.1641 12.5L10.6641 10M10.6641 10L8.16406 12.5M10.6641 10L10.6641 15"
                      stroke="#0F7691"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {uploadFile.value ? (
                  <p className={"mb-0 " + styles["label-filled"]}>
                    {uploadFile.filename}
                  </p>
                ) : (
                  <p className={"mb-0 " + styles["label-title"]}>
                    Upload Image
                  </p>
                )}
                {uploadFile.value ? (
                  <svg
                    width="16"
                    className="ms-auto"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.83958 2.40006L3.36624 8.1934C3.15958 8.4134 2.95958 8.84673 2.91958 9.14673L2.67291 11.3067C2.58624 12.0867 3.14624 12.6201 3.91958 12.4867L6.06624 12.1201C6.36624 12.0667 6.78624 11.8467 6.99291 11.6201L12.4662 5.82673C13.4129 4.82673 13.8396 3.68673 12.3662 2.2934C10.8996 0.913397 9.78624 1.40006 8.83958 2.40006Z"
                      stroke="#0F7691"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7.92578 3.3667C8.21245 5.2067 9.70578 6.61337 11.5591 6.80003"
                      stroke="#0F7691"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 14.6666H14"
                      stroke="#0F7691"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <></>
                )}
              </div>
              <p className={styles["upload-file-desc"] + " mt-1"}>
                Upload optimised image
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className={"btn " + styles["btn-cancel"]} onClick={close}>
            CANCEL
          </button>
          {!loading ? (
            <button
              className={
                "btn " +
                styles["btn-submit"] +
                " " +
                (!uploadFile.value || mediaName === "" ? styles["disabled"] : "")
              }
              onClick={onSubmit}
            >
              UPLOAD
            </button>
          ) : (
            <button
              className={
                "btn " +
                styles["btn-submit"] +
                " " +
                (loading ? styles["disabled"] : "")
              }
            >
              UPLOADING....
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UploadImage;
