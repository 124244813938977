import React, { useEffect, useState } from "react";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import { Link, useLocation } from "react-router-dom";
import {
  fetchPropertyBilling,
  updatePropertyBillings,
} from "../../helpers/services/api.services";
import DataTable from "react-data-table-component";
import {
  fetchCities,
  fetchRoomTypes,
} from "../../helpers/services/admin.services";
import { Form, Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useFormik } from "formik";

const SalesList = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [billingList, setBillingList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [serachObject, setSerachObject] = useState({});
  const [sortObject, setSortObject] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterAcitveCount, setFilterAcitveCount] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [newValue, setNewValue] = useState(null);
  const [activeDropDown, setActiveDropdown] = useState(false);
  const [dropdownValue, setdropdownValue] = useState(true);

  const handleCloseFilterModal = () => setShowFilterModal(false);
  const handleShowFilterModal = () => {
    //   if(Object.keys(serachObject).length){
    //     Object.keys(serachObject).map((key)=>{
    //       formik.setFieldValue(key, serachObject[key])
    //     })
    //   }
    setShowFilterModal(true);
  };

  const handleUpdateAmount = async (active) => {
    const data = {
      amount: Number(newValue),
      is_active: active,
    };
    const response = await updatePropertyBillings(selectedId, data);

    if (response && response?.status == "success") {
      toast.success("Updation successfull");
    } else {
      toast.error("Error while updating");
    }
    loadpropertyBilling(page, serachObject, sortObject, rowsPerPage);
    setSelectedId(null);
    setNewValue(null);
    setActiveDropdown(false);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      width: "5%",
    },
    {
      name: "CITY ",
      selector: (row) => row.city_name,
      width: "5%",
      sortField: "city_name",
    },
    {
      name: "Property ",
      selector: (row) => row.property_name,
      width: "15%",
      sortField: "property_name",
    },
    {
      name: "Room Type ",
      selector: (row) => row.room_type_name,
      width: "15%",
      sortField: "room_type_name",
    },
    {
      name: "Bill Type ",
      selector: (row) => row.bill_name,
      width: "15%",
      sortField: "bill_name",
    },
    {
      name: "Frequency ",
      selector: (row) => row.frequency,
      width: "10%",
      sortField: "frequency",
    },

    {
      name: "Amount ",
      selector: (row) => row.amount,
      width: "20%",
      cell: (row) => (
        <>
          {selectedId == row.id && !activeDropDown ? (
            <>
              {" "}
              <div className="d-flex justify-content-between">
                <div>
                  <input
                    type="number"
                    className="border border-secondary py-1 px-2 border-1 rounded  focus-ring-none mt-1"
                    value={newValue}
                    style={{width:"100px"}}
                    onChange={(e) => setNewValue(e.target.value)}
                  />{" "}
                </div>
                <div className="mx-3 mt-1 d-flex">
                  <span
                    className="mx-1 mt-1"
                    onClick={() => handleUpdateAmount(true)}
                  >
                    <img
                      src="/images/check-verified.svg"
                      className="pr-1"
                      alt=""
                    />
                  </span>{" "}
                  <span
                    onClick={() => {
                      setSelectedId(null);
                      setNewValue(null);
                    }}
                  >
                    <img
                      src="/images/cross_modal.svg"
                      className="pr-1"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                onClick={() => {
                  setSelectedId(row.id);
                  setNewValue(row.amount);
                  setActiveDropdown(false);
                }}
              >
                {row.amount}
              </div>
            </>
          )}
        </>
      ),
    },
    {
      name: "Active ",
      selector: (row) => row.is_active,
      width: "15%",
      cell: (row) => (
        <>
          {selectedId == row.id && activeDropDown ? (
            <>
              <div className="mx-2 d-flex">
                <select
                  name="active"
                  id="active"
                  className="border-none rounded  focus-ring-none px-1"
                  onChange={(e) => setdropdownValue(e.target.value)}
                >
                  <option value="true">true</option>
                  <option value="false">false</option>
                </select>
                <div className="mx-3 mt-1 d-flex">
                  <span
                    className="mx-1 mt-2"
                    onClick={() => handleUpdateAmount(dropdownValue)}
                  >
                    <img
                      src="/images/check-verified.svg"
                      className="pr-1"
                      alt=""
                    />
                  </span>{" "}
                  <span
                    onClick={() => {
                      setSelectedId(null);
                      setNewValue(null);
                      setdropdownValue(true);
                    }}
                  >
                    <img
                      src="/images/cross_modal.svg"
                      className="pr-1"
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="mx-2"
                onClick={() => {
                  setSelectedId(row.id);
                  setNewValue(row.amount);
                  setActiveDropdown(true);
                  setdropdownValue(true);
                }}
              >
                {row.is_active ? "true" : "false"}
              </div>
            </>
          )}
        </>
      ),
    },
  ];

  const loadpropertyBilling = async (
    currentPage,
    searchPayload,
    sortPayload,
    current_rowPerPage
  ) => {
    setLoading(true);
    let response = await fetchPropertyBilling(
      currentPage,
      searchPayload,
      sortPayload,
      current_rowPerPage
    );

    if (
      response &&
      response?.status == "success" &&
      response.data &&
      response.data.property_billings?.length > 0
    ) {
      setBillingList(response.data.property_billings);

      if (response && response?.data?.total_count && response?.data?.page) {
        setTotalRows(response?.data?.total_count);
      }

      setPage(currentPage);
      setLoading(false);
      handleCloseFilterModal();
    } else {
      setBillingList([]);
      setLoading(false);
      handleCloseFilterModal();
    }
  };

  const rowPerPageChange =(currentRowsPerPage, currentPage)=>{
    // console.log("currentRowsPerPage, currentPage -- ",currentRowsPerPage, currentPage)
    setPage(currentPage)
    loadpropertyBilling(currentPage, serachObject, sortObject,currentRowsPerPage)
  }

  const initialValues = {
    city_name: queryParams.get("city_name") || "",
    id: "",
    billing_name: "",
    frequency: "",
    room_type_name: "",
    property_name: "",
  };

  const loadfun = (values) => {
    let search_obj = JSON.parse(JSON.stringify(values));
    const has_value = Object.values(values).some((val) => (val ? true : false));
    Object.keys(values).map((key) => {
      if (!values[key]) {
        delete search_obj[key];
      }
    });
    if (has_value) {
      setSerachObject(search_obj);
      setFilterAcitveCount(Object.keys(search_obj).length);
      loadpropertyBilling(1, search_obj, sortObject, rowsPerPage);
    }
  };

  const validationSchema = null;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      let search_obj = JSON.parse(JSON.stringify(values));
      const has_value = Object.values(values).some((val) =>
        val ? true : false
      );
      Object.keys(values).map((key) => {
        if (!values[key]) {
          delete search_obj[key];
        }
      });
      if (has_value) {
        setSerachObject(search_obj);
        setFilterAcitveCount(Object.keys(search_obj)?.length);
        loadpropertyBilling(1, search_obj, sortObject, rowsPerPage);
      }
    },
  });

  const handleClickFilterClose = (e) => {
    e.stopPropagation();
    setSerachObject({});
    setFilterAcitveCount(0);
    formik.resetForm();
    loadpropertyBilling(page, {}, sortObject, rowsPerPage);
  };

  const handlePageChange = (page) => {
    loadpropertyBilling(page, serachObject, sortObject, rowsPerPage);
  };

  useEffect(() => {
    // loadfun(initialValues);
    loadpropertyBilling(page, serachObject, sortObject, rowsPerPage);
  }, []);

  const handleResetFilter = (e) => {
    formik.resetForm();
  };

  // useEffect(() => {
  //   loadpropertyBilling(page, serachObject, sortObject, rowsPerPage);
  //   loadCities();
  // }, []);

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Sales</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Sales</a> <span>/</span>
              </li>
              <li>Property Billings</li>
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-2">
          {" "}
          <div className="status-box">
            <a
              onClick={handleShowFilterModal}
              className="cursor-pointor"
              style={{
                display: "flex",
                direction: "row",
              }}
            >
              <img src="/images/filter.svg" className="pr-1" alt="" /> Filter
              {filterAcitveCount > 0 ? (
                <>
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      backgroundColor: "#FF5700",
                      borderRadius: "50%",
                      marginLeft: 4,
                      color: "#FFF4ED",
                      textAlign: "center",
                      justifyContent: "center",
                      font: "Manrope",
                      fontSize: 11,
                      fontStyle: "normal",
                      fontWeight: 800,
                      letterSpacing: "0.88px",
                    }}
                  >
                    {filterAcitveCount}
                  </div>
                  <button
                    style={{
                      background: "none",
                      border: "none",
                    }}
                    onClick={handleClickFilterClose}
                  >
                    <img
                      src="/images/close_with_bg.svg"
                      className="pr-1"
                      alt=""
                    />
                  </button>
                </>
              ) : null}
            </a>
          </div>
        </div>
      </div>

      <div className="sr_data_table">
        <DataTable
          columns={columns}
          data={billingList.sort((a, b) => a.id - b.id)}
          progressPending={loading}
          pagination
          paginationServer
          onChangePage={handlePageChange}
          paginationTotalRows={totalRows}
          fixedHeader={true}
          fixedHeaderScrollHeight={"485px"}
          sortServer
          onChangeRowsPerPage={rowPerPageChange}
        />
      </div>

      <Modal
        show={showFilterModal}
        onHide={handleCloseFilterModal}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title>Filters</Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={handleCloseFilterModal}
          />
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="property_name">
                    Property Name
                  </label>
                  <input
                    type="text"
                    id="property_name"
                    name="property_name"
                    placeholder="Enter Property Name"
                    className="form-control"
                    value={formik.values.property_name}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "property_name",
                        e.target.value.trim()
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="city_name">
                    City Name
                  </label>
                  <input
                    type="text"
                    id="city_name"
                    name="city_name"
                    placeholder="Enter City Name"
                    className="form-control"
                    value={formik.values.city_name}
                    onChange={(e) =>
                      formik.setFieldValue("city_name", e.target.value.trim())
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="frequency">
                    Frequency
                  </label>
                  <input
                    type="text"
                    id="frequency"
                    name="frequency"
                    placeholder="Enter Frequency"
                    className="form-control"
                    value={formik.values.frequency}
                    onChange={(e) =>
                      formik.setFieldValue("frequency", e.target.value.trim())
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="billing_name">
                    Billing Name
                  </label>
                  <input
                    type="text"
                    id="billing_name"
                    name="billing_name"
                    placeholder="Enter Billing Name"
                    className="form-control"
                    value={formik.values.billing_name}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "billing_name",
                        e.target.value.trim()
                      )
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6">
                <div className="form_control_box">
                  <label className="form-label" htmlFor="room_type_name">
                    room Type
                  </label>
                  <input
                    type="text"
                    id="room_type_name"
                    name="room_type_name"
                    placeholder="Enter room Type"
                    className="form-control"
                    value={formik.values.room_type_name}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "room_type_name",
                        e.target.value.trim()
                      )
                    }
                  />
                </div>
              </div>
            </div>

            <div className="modal_btn_footer my-4 mx-4">
              <button
                type="button"
                className="orange_brd_button p-12-16-button mr-3"
                style={{ width: "fit-content" }}
                onClick={handleResetFilter}
              >
                RESET
              </button>
              <button type="submit" className={"basic-button"}>
                SEARCH
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withDefaultDashBoardLayout(SalesList);
