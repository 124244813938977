import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateWebsiteFaqs } from "../../../../helpers/services/websiteServices";
import toast from "react-hot-toast";

const ModalFaQ = ({
  faqConfigData,
  handler,
  openModal,
  faqIndex,
  actionType,
}) => {
  const [data, setData] = useState({
    question: "",
    answer: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const faqData = async () => {
    if (actionType == "update") {
      const filterData = faqConfigData.filter((data, ind) => ind === faqIndex);
      setData(filterData[0]);
    } else {
      setData({
        question: "",
        answer: "",
      });
    }
  };
  useEffect(() => {
    if (actionType == "add") {
      setData({
        question: "",
        answer: "",
      });
    }
  }, [actionType]);

  const clearData = () => {
    setData({
      question: "",
      answer: "",
    });
  };

  useEffect(() => {
    if (actionType == "update") {
      faqData();
    }
  }, [faqConfigData, faqIndex]);

  const handleSubmit = async () => {
    if(data?.question.trim() == "" || data?.answer.trim()== ""){
      return toast.error("Please fill all details")
    }
    try {
      if (actionType == "update") {
        faqConfigData[faqIndex] = data;
      } else if (actionType == "add") {
        faqConfigData.push(data);
      }
      const bodyData = {
        config_details: faqConfigData,
      };
      const response = await updateWebsiteFaqs(bodyData);
      if (response.status == "success") {
        toast.success(
          `${
            actionType == "update"
              ? `Faq #${faqIndex + 1} updated`
              : "New Faq added"
          }`
        );
        handler();
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => {
          handler();
          clearData();
        }}
        centered
        size="lg"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title className="underline">
            {actionType == "add" ? "Add Faq" : `Faq #${faqIndex + 1}`}
          </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={handler}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="form_control_box">
            <label htmlFor="faq-question" className="form-label">
              <b>Question</b>
            </label>
            <input
              type="text"
              id="faq-question"
              className="form-control mt-1"
              name="question"
              onChange={handleChange}
              value={data?.question}
            />
          </div>
          <div className="form_control_box">
            <label htmlFor="faq-question" className="form-label">
              <b>Answer</b>
            </label>
            <textarea
              type="text"
              id="faq-question"
              className="form-control mt-1"
              name="answer"
              onChange={handleChange}
              value={data?.answer}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className={"basic-button"}
            onClick={handleSubmit}
          >
            {actionType == "add" ? "ADD NEW FAQ" : "UPDATE"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalFaQ;
